import { Amount } from "./amount";
import { RemittanceType } from "./enum/remittance-type";

export class InternalInvoice {
  id: string = "";
  year: string = "";
  month: string = "";
  status: string = "";
  recoveredAmount!: Amount;
  amount!: Amount;
  items: InternalInvoiceItem[] = [];

  get yearMonth() {
    return this.year + "/" + this.month;
  }
}

export class InternalInvoiceItem {
  payeeCode: string = "";
  accountName: string = "";
  remittanceNumber: string = "";
  invoiceNumber: string = "";
  invoiceDate: string = "";
  paymentDueDate: string = "";
  type?: RemittanceType = undefined;
  fee: number = 0;
  recoveredAmount!: Amount;
  amount!: Amount;
}
