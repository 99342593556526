import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Permission, permissions } from "@/models/enum/permission";
import { authService, roleService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import Loader from "@app/components/loader";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

export default function RolePage() {
  const [form] = Form.useForm();
  const role = useObservable(roleService.role);
  const isFetching = useObservable(roleService.isFetchingOne);
  const isUpdating = useObservable(roleService.isUpdating);
  const isDeleting = useObservable(roleService.isDeleting);
  const router = useNavigate();
  const claims = useObservable(authService.claims);
  const manageRole =
    claims?.permissions.includes(Permission.ManageRoles) ?? false;
  const roleId = useParams().id!!;
  const [t] = useTranslation();

  useEffect(() => {
    roleService.getRole(roleId);

    return () => {
      roleService.disposeRole();
    };
  }, [roleId]);

  const onSubmit = ({
    name,
    permissions,
  }: {
    name: string;
    permissions: string[];
  }) => {
    roleService.updateRole(roleId, name, permissions).then((response) => {
      if (response) {
        message.success("Role updated", 4);
      }
    });
  };

  const onDelete = () => {
    roleService.deleteRole(roleId).then((response) => {
      if (response) {
        message.success("Role deleted", 4);
        router("/settings/roles");
      }
    });
  };

  if (!role || isFetching) return <Loader />;

  return (
    <Row justify="center">
      <div style={{ width: "350px" }}>
        <Row justify="center">
          <Title level={3}>{role.name}</Title>
        </Row>
        <Divider />
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            initialValue={role.name}
            label="Name"
            name="name"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={role.permissions}
            label="Permissions"
            name="permissions"
          >
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              allowClear
              filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {permissions.map((permission) => (
                <Option key={permission.code} value={permission.code}>
                  {permission.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {manageRole && (
            <Form.Item>
              <Button
                type="primary"
                block
                loading={isUpdating}
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          )}
          {manageRole && (
            <Form.Item>
              <Button
                type="primary"
                disabled={claims?.roleId === roleId}
                block
                danger
                loading={isDeleting}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </Row>
  );
}
