import { Col, Divider, Form, Input, Row, Table, Typography } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Account } from "@/models/vendor-central-credential";
import { useObservable } from "@/utils/use-observable";
import Loader from "@app/components/loader";
import "./index.less";
import { ColumnsType } from "antd/lib/table";
import { renderBoolean, renderDate } from "@/utils";
import { vendorCentralCredentialService } from "@/services";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function VendorCentralCredentialPage() {
  const [form] = Form.useForm();
  const isFetching = useObservable(
    vendorCentralCredentialService.isFetchingOne
  );
  const vendorCentralCredential = useObservable(
    vendorCentralCredentialService.vendorCentralCredential
  );
  const vendorCentralCredentialId = useParams().id!!;
  const [t] = useTranslation();

  useEffect(() => {
    vendorCentralCredentialService.getVendorCentralCredential(
      vendorCentralCredentialId
    );

    return () => {
      vendorCentralCredentialService.disposeCredential();
    };
  }, [vendorCentralCredentialId]);

  const columns: ColumnsType<Account> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "250px",
    },
    {
      title: "Payee Code",
      dataIndex: "payeeCode",
      key: "payeeCode",
      width: "100px",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      width: "100px",
      align: "center",
    },
    {
      title: "Last Settlement",
      dataIndex: "lastSettlement",
      key: "lastSettlement",
      width: "120px",
      render: renderDate,
    },
    {
      title: "Updating",
      dataIndex: "updating",
      key: "updating",
      width: "50px",
      align: "center",
      render: renderBoolean,
    },
    {
      title: "Last Update",
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Disabled",
      dataIndex: "disabled",
      key: "disabled",
      width: "50px",
      align: "center",
      render: renderBoolean,
    },
  ];

  if (!vendorCentralCredential || isFetching) {
    return <Loader />;
  }

  return (
    <Row className="vendor-central-credential-page">
      <Col flex={1}>
        <Form layout="vertical" form={form}>
          <Row justify="center">
            <div style={{ width: "900px" }}>
              <Row justify="center">
                <Title level={3}>{vendorCentralCredential.email}</Title>
              </Row>
              <Divider />

              <Form.Item
                initialValue={vendorCentralCredential.email}
                label="Email"
                name="email"
                rules={[{ required: true, message: t("error.fieldRequired") }]}
              >
                <Input />
              </Form.Item>
            </div>
          </Row>
          <center>
            <div style={{ maxWidth: "900px" }}>
              <Form.Item label="Accounts" name="accounts">
                <Table
                  style={{ maxWidth: 900 }}
                  dataSource={vendorCentralCredential.accounts}
                  pagination={false}
                  columns={columns}
                  rowKey="name"
                  size="small"
                  scroll={{ x: 900 }}
                />
              </Form.Item>
            </div>
          </center>
        </Form>
      </Col>
    </Row>
  );
}
