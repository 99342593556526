import { authService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import { UserForm } from "@app/components/user-form";
import "./index.less";

export default function AccountPage() {
  const claims = useObservable(authService.claims);

  return (
    <div className="account-page">
      <UserForm id={claims!!.sub} account={true} />
    </div>
  );
}
