import { BehaviorSubject, map } from "rxjs";
import { authService } from ".";
import {
  SelectAccount,
  VendorCentralCredential,
} from "@/models/vendor-central-credential";

export class VendorCentralCredentialService {
  isFetching = new BehaviorSubject<boolean>(false);
  vendorCentralCredentials = new BehaviorSubject<VendorCentralCredential[]>([]);
  allAccounts = this.vendorCentralCredentials.pipe(
    map((credentials) =>
      credentials
        .flatMap((credential) =>
          credential.accounts.map(
            (account) => new SelectAccount(account.name, account.payeeCode)
          )
        )
        .sort((a, b) => (a.value < b.value ? -1 : 1))
    )
  );
  isFetchingOne = new BehaviorSubject<boolean>(false);
  vendorCentralCredential = new BehaviorSubject<VendorCentralCredential | null>(
    null
  );

  dispose = async () => {
    this.isFetching.next(false);
    this.vendorCentralCredentials.next([]);
  };

  disposeCredential = async () => {
    this.vendorCentralCredential.next(null);
    this.isFetching.next(false);
  };

  getVendorCentralCredentials = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        "/vendor-central-credentials",
        {
          method: "GET",
        }
      );
      if (response?.ok) {
        this.vendorCentralCredentials.next(
          ((await response.json()) as VendorCentralCredential[]).map((x) =>
            Object.assign(new VendorCentralCredential(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getVendorCentralCredential = async (id: string) => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        "/vendor-central-credentials/" + id,
        {
          method: "GET",
        }
      );
      if (!!response?.ok) {
        this.vendorCentralCredential.next(
          Object.assign(new VendorCentralCredential(), await response.json())
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };
}
