import { BehaviorSubject } from "rxjs";
import { Role } from "@/models/role";
import { authService } from ".";

export class RoleService {
  roles = new BehaviorSubject<Role[]>([]);
  isFetching = new BehaviorSubject<boolean>(false);
  role = new BehaviorSubject<Role | null>(null);
  isFetchingOne = new BehaviorSubject<boolean>(false);
  isAdding = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);
  isDeleting = new BehaviorSubject<boolean>(false);

  dispose = async () => {
    this.roles.next([]);
  };

  disposeRole = async () => {
    this.role.next(null);
  };

  getRoles = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch("/roles", { method: "GET" });
      if (response?.ok) {
        this.roles.next(
          ((await response.json()) as Role[]).map((x) =>
            Object.assign(new Role(), x)
          )
        );
      }
    } catch (e) {
    } finally {
      this.isFetching.next(false);
    }
  };

  getRole = async (id: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch("/roles/" + id, {
        method: "GET",
      });
      if (!!response?.ok) {
        this.role.next(Object.assign(new Role(), await response.json()));
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  addRole = async (name: string, permissions: string[]) => {
    try {
      this.isAdding.next(true);
      const response = await authService.authFetch("/roles", {
        method: "POST",
        body: JSON.stringify({ name, permissions }),
      });
      if (!!response?.ok) {
        this.getRoles();
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isAdding.next(false);
    }
    return false;
  };

  updateRole = async (id: string, name: string, permissions: string[]) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch("/roles", {
        method: "PATCH",
        body: JSON.stringify({ id, name, permissions }),
      });

      if (!!response?.ok) {
        this.role.next(Object.assign(this.role.value!!, { name }));
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };

  deleteRole = async (id: string) => {
    try {
      this.isDeleting.next(true);
      const response = await authService.authFetch("/roles/" + id, {
        method: "DELETE",
      });
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isDeleting.next(false);
    }
    return false;
  };
}
