import { Amount } from "./amount";
import { CreditMemoStatus } from "./enum/credit-memo-status";
import { VarianceStatus } from "./enum/variance-status";

export class Invoice {
  id: string = "";
  accountName: string = "";
  number: string = "";
  date: string = "";
  vendorCentralStatus: string = "";
  status: string = "";
  payeeCode: string = "";
  paymentDueDate: string = "";
  deliveryDate: string = "";
  amount!: Amount;
  actualPaidAmount?: Amount;
  approvedOn: string = "";
  paidOn: string = "";
  items?: InvoiceItem[] = undefined;
  shortageClaims: ShortageClaim[] = [];
  priceClaims: PriceClaim[] = [];
  dispute?: Dispute = undefined;
  creditMemos: CreditMemo[] = [];
  purchaseQuantityVarianceStatus?: VarianceStatus = undefined;
  purchasePriceVarianceStatus?: VarianceStatus = undefined;
  creditMemoStatus?: CreditMemoStatus = undefined;
  priceClaim?: PriceClaim = undefined;
  remittanceNumber?: string;
  destination?: string;

  get claims() {
    return [...this.shortageClaims.map(sc => Object.assign(new ShortageClaim(), sc)), ...this.priceClaims.map(pc => Object.assign(new PriceClaim(), pc))].sort((a, b) => a.number.localeCompare(b.number));
  }

  get remainingAmount(): Amount {
    let invoice: Invoice | ShortageClaim;
    if (this.shortageClaims.length === 0) {
      invoice = this;
    } else {
      invoice = this.shortageClaims[this.shortageClaims.length - 1];
    }

    const amount = Object.assign({}, invoice.amount);
    if (invoice?.actualPaidAmount?.value) {
      amount.value = amount.value - invoice.actualPaidAmount.value;
    }
    return amount;
  }
}

export class InvoiceItem {
  purchaseOrder: string = "";
  asin: string = "";
  externalId: string = "";
  title?: string = undefined;
  model?: string = undefined;
  freightTerm?: string = undefined;
  quantity?: number = undefined;
  costPrice?: Amount = undefined;
  totalAmount?: Amount = undefined;
  shortageQuantity?: number = undefined;
  amountShortage?: Amount = undefined;
  lastReceivedDate?: string = undefined;
  receivedAsin?: string = undefined;
  receivedQuantity?: number = undefined;
  unitCost?: Amount = undefined;
  amountReceived?: Amount = undefined;
}

export class ShortageClaim {
  number: string = "";
  date?: string = undefined;
  vendorCentralStatus?: string = undefined;
  status?: string = undefined;
  amount!: Amount;
  actualPaidAmount?: Amount = undefined;
  approvedOn: string = "";
  paidOn: string = "";
  paymentDueDate: string = "";
  items?: InvoiceItem[] = undefined;
  dispute?: Dispute = undefined;
  creditMemos?: CreditMemo[] = undefined;
  remittanceNumbers?: string[];
  remittanceReversalNumbers?: string[];
}

export class PriceClaim {
  number: string = "";
  date?: string = undefined;
  vendorCentralStatus?: string = undefined;
  status?: string = undefined;
  paymentDueDate: string = "";
  approvedOn: string = "";
  amount!: Amount;
  actualPaidAmount?: Amount = undefined;
  paidOn: string = "";
  items?: PriceClaimItem[] = undefined;
  dispute?: Dispute = undefined;
  creditMemos?: CreditMemo[] = undefined;
  remittanceNumbers?: string[];
  remittanceReversalNumbers?: string[];
}

export class PriceClaimItem {
  purchaseOrder: string = "";
  asin: string = "";
  externalId: string = "";
  title?: string = undefined;
  quantity?: number = undefined;
  invoiceCost?: Amount = undefined;
  initialResearchCost?: Amount = undefined;
  resolutionDecision?: string = undefined;
  resolutionCost?: Amount = undefined;
}

export class Dispute {
  disputeId?: string = undefined;
  date?: string = undefined;
  type?: string = undefined;
  status?: string = undefined;
  cases?: Case[] = undefined;
  reminders?: string[] = undefined;
}

export class Case {
  caseId?: string = undefined;
  date?: string = undefined;
  status?: string = undefined;
  reminders?: string[] = undefined;
}

export class CreditMemo {
  number?: string = undefined;
  status?: string = undefined;
  amount?: Amount = undefined;
  date?: string = undefined;
  remittanceNumbers?: string[] = undefined;
}

