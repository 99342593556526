// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  min-height: 100% !important;
}
.layout .sider {
  position: fixed;
  height: 100vh;
  overflow: hidden;
}
.layout .sider .menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.layout .sider .menu .logo {
  padding: 20px 0px;
  text-align: center;
}
.layout .sider .menu .space {
  flex: 1;
}
.layout .sider .menu .info-menu {
  margin-bottom: 10px;
}
.layout .menu-button {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  z-index: 999;
}
.layout .content {
  margin-left: 250px;
  transition: margin-left 0.2s;
}
.layout .content.collapsed {
  margin-left: 50px;
}
@media screen and (max-width: 768px) {
  .layout .ant-layout-sider {
    display: none !important;
  }
  .layout .content {
    margin-left: 0 !important;
  }
  .layout .menu-button {
    display: inherit;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/main/index.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AAFA;EAII,eAAA;EACA,aAAA;EACA,gBAAA;AACJ;AAPA;EASM,aAAA;EACA,sBAAA;EACA,YAAA;AACN;AAZA;EAcQ,iBAAA;EACA,kBAAA;AACR;AAhBA;EAmBQ,OAAA;AAAR;AAnBA;EAuBQ,mBAAA;AADR;AAtBA;EA6BI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;AAJJ;AA7BA;EAqCI,kBAAA;EACA,4BAAA;AALJ;AAOI;EACE,iBAAA;AALN;AASE;EAAA;IAEI,wBAAA;EAPJ;EAKA;IAKI,yBAAA;EAPJ;EAEA;IAQI,gBAAA;EAPJ;AACF","sourcesContent":[".layout {\n  min-height: 100% !important;\n\n  .sider {\n    position: fixed;\n    height: 100vh;\n    overflow: hidden;\n\n    .menu {\n      display: flex;\n      flex-direction: column;\n      height: 100%;\n\n      .logo {\n        padding: 20px 0px;\n        text-align: center;\n      }\n\n      .space {\n        flex: 1;\n      }\n\n      .info-menu {\n        margin-bottom: 10px;\n      }\n    }\n  }\n\n  .menu-button {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    display: none;\n    z-index: 999;\n  }\n\n  .content {\n    margin-left: 250px;\n    transition: margin-left 0.2s;\n\n    &.collapsed {\n      margin-left: 50px;\n    }\n  }\n\n  @media screen and (max-width: 768px) {\n    .ant-layout-sider {\n      display: none !important;\n    }\n    .content {\n      margin-left: 0 !important;\n    }\n    .menu-button {\n      display: inherit;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
