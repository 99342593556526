// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-page {
  padding: 20px;
}
.integration-main-col {
  padding-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/integration/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,mBAAA;AAAF","sourcesContent":[".integration-page {\n  padding: 20px;\n}\n\n.integration-main-col {\n  padding-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
