import { BehaviorSubject } from "rxjs";
import { AdminUser } from "@/models/user";
import { authService } from ".";

export class AdminUserService {
  users = new BehaviorSubject<AdminUser[]>([]);
  isFetching = new BehaviorSubject<boolean>(false);
  user = new BehaviorSubject<AdminUser | null>(null);
  isFetchingOne = new BehaviorSubject<boolean>(false);
  isAdding = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);
  isDeleting = new BehaviorSubject<boolean>(false);
  isTogglingStatus = new BehaviorSubject<boolean>(false);

  dispose = async () => {
    this.users.next([]);
  };

  disposeUser = async () => {
    this.user.next(null);
  };

  getUsers = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch("/admin/users", {
        method: "GET",
      });
      if (response?.ok) {
        this.users.next(
          ((await response.json()) as AdminUser[]).map((x) =>
            Object.assign(new AdminUser(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getUser = async (id: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch("/admin/users/" + id, {
        method: "GET",
      });
      if (!!response?.ok) {
        this.user.next(Object.assign(new AdminUser(), await response.json()));
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  addUser = async (
    name: string,
    password: string,
    email: string,
    roleId: string,
    organizationId: string
  ) => {
    try {
      this.isAdding.next(true);
      const response = await authService.authFetch("/admin/users", {
        method: "POST",
        body: JSON.stringify({ name, password, email, roleId, organizationId }),
      });
      if (!!response?.ok) {
        this.refresh();
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isAdding.next(false);
    }
  };

  updateUser = async (
    id: string,
    name: string,
    email: string,
    roleId: string
  ) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch("/admin/users", {
        method: "PATCH",
        body: JSON.stringify({ id, name, email, roleId }),
      });

      if (!!response?.ok) {
        this.user.next(Object.assign(this.user.value!!, { name }));
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };

  deleteUser = async (id: string) => {
    try {
      this.isDeleting.next(true);
      const response = await authService.authFetch("/admin/users/" + id, {
        method: "DELETE",
      });
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isDeleting.next(false);
    }
    return false;
  };

  enableUser = async (id: string, refresh: boolean = true) => {
    try {
      this.isTogglingStatus.next(true);
      const response = await authService.authFetch(
        `/admin/users/${id}/enable`,
        {
          method: "POST",
        }
      );
      if (!!response?.ok) {
        if (refresh) {
          this.refresh();
        } else {
          this.getUser(id);
        }
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isTogglingStatus.next(false);
    }
    return false;
  };

  disableUser = async (id: string, refresh: boolean = true) => {
    try {
      this.isTogglingStatus.next(true);
      const response = await authService.authFetch(
        `/admin/users/${id}/disable`,
        {
          method: "POST",
        }
      );
      if (!!response?.ok) {
        if (refresh) {
          this.refresh();
        } else {
          this.getUser(id);
        }
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isTogglingStatus.next(false);
    }
    return false;
  };
}
