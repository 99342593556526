import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { permissions } from "@/models/enum/permission";
import { AdminRole, Role } from "@/models/role";
import { adminOrganizationService, adminRoleService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import "./index.less";

const { Search } = Input;
const { Option } = Select;

export default function AdminRolesPage() {
  const columns = [
    {
      title: "Name",
      key: "name",
      width: "200px",
      sorter: {
        compare: (a: Role, b: Role) => (a.name > b.name ? -1 : 1),
        multiple: 3,
      },
      render: (_: string, row: Role) => <Link to={row.id}>{row.name}</Link>,
    },
    {
      title: "Organization",
      key: "organization",
      width: "200px",
      sorter: {
        compare: (a: AdminRole, b: AdminRole) =>
          a.organization.name > b.organization.name ? -1 : 1,
        multiple: 2,
      },
      render: (_: string, row: AdminRole) => row.organization.name,
    },
  ];

  const roles = useObservable(adminRoleService.roles);
  const isFetching = useObservable(adminRoleService.isFetching);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    adminRoleService.getRoles();
  }, []);

  const onRefresh = () => {
    adminRoleService.refresh();
  };

  const onSearch = (value: string) => {
    setSearchText(value.toLowerCase());
  };

  return (
    <div className="roles-page">
      <Row className="actions" justify="start" gutter={[8, 8]}>
        <Col className="action" sm={8} xs={24}>
          <AddRoleButton />
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Button
            style={{ width: "100%" }}
            icon={<SyncOutlined spin={isFetching} />}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search role"
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <Table
        style={{ maxWidth: "800px" }}
        columns={columns}
        dataSource={roles.filter((role) =>
          role.name.toLowerCase().includes(searchText)
        )}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
        scroll={{ x: 400 }}
      />
    </div>
  );
}

function AddRoleButton() {
  const [newRoleVisible, setNewRoleVisible] = useState(false);

  const showNewRoleModal = () => {
    setNewRoleVisible(true);
  };

  const hideNewRoleModal = (added: boolean) => {
    setNewRoleVisible(false);
    if (added) {
      adminRoleService.getRoles();
    }
  };

  return (
    <>
      <Button
        style={{ width: "100%" }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={showNewRoleModal}
      >
        New Role
      </Button>
      {newRoleVisible && (
        <RoleNewForm visible={true} onFinish={hideNewRoleModal} />
      )}
    </>
  );
}

function RoleNewForm(props: {
  visible: boolean;
  onFinish: (added: boolean) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isAdding = useObservable(adminRoleService.isAdding);
  const organizations = useObservable(adminOrganizationService.organizations);

  useEffect(() => {
    adminOrganizationService.getOrganizations();
  }, []);

  const onSubmit = ({
    name,
    permissions,
    organization,
  }: {
    name: string;
    permissions: string[];
    organization: string;
  }) => {
    adminRoleService
      .addRole(name, permissions, organization)
      .then((response) => {
        if (response) {
          message.success("Role added", 4);
          props.onFinish(true);
          form.resetFields();
        }
      });
  };

  const onCancel = () => {
    props.onFinish(false);
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={onCancel}
      title="New Role"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAdding}
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item initialValue={[]} label="Permissions" name="permissions">
          <Select
            showSearch
            optionFilterProp="children"
            mode="multiple"
            allowClear
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {permissions.map((permission) => (
              <Option key={permission.code} value={permission.code}>
                {permission.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Organization"
          name="organization"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {organizations.map((organization) => (
              <Option key={organization.id} value={organization.id}>
                {organization.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
