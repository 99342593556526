export enum RequestStatus {
  SUBMITTED = "SUBMITTED",
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}

export const requestStatusList = [
  { value: RequestStatus.SUBMITTED, label: "Submitted" },
  { value: RequestStatus.IN_PROGRESS, label: "In progress", color: "blue" },
  { value: RequestStatus.FAILED, label: "Failed", color: "red" },
  { value: RequestStatus.SUCCEEDED, label: "Succeeded", color: "green" },
];
