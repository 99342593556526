export enum DisputeInvoiceAction {
  CREATE_DISPUTE = "CREATE_DISPUTE",
  REMIND_DISPUTE = "REMIND_DISPUTE",
  CREATE_CASE = "CREATE_CASE",
  REMIND_CASE = "REMIND_CASE",
}

export const disputeInvoiceActionsList = [
  { value: DisputeInvoiceAction.CREATE_DISPUTE, label: "Create Dispute" },
  {
    value: DisputeInvoiceAction.REMIND_DISPUTE,
    label: "Remind Dispute",
  },
  { value: DisputeInvoiceAction.CREATE_CASE, label: "Create Case" },
  { value: DisputeInvoiceAction.REMIND_CASE, label: "Remind Case" },
];
