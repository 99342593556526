import { BehaviorSubject, map } from "rxjs";
import { authService } from ".";
import {
  AdminVendorCentralCredential,
  SelectAccount,
} from "@/models/admin-vendor-central-credential";

export class AdminVendorCentralCredentialService {
  isFetching = new BehaviorSubject<boolean>(false);
  vendorCentralCredentials = new BehaviorSubject<
    AdminVendorCentralCredential[]
  >([]);
  allAccounts = this.vendorCentralCredentials.pipe(
    map((credentials) =>
      credentials
        .flatMap((credential) =>
          credential.accounts.map(
            (account) =>
              new SelectAccount(
                account.name,
                account.payeeCode,
                credential.organizationId
              )
          )
        )
        .sort((a, b) =>
          a.organizationId + a.value < b.organizationId + b.value ? -1 : 1
        )
    )
  );
  isFetchingOne = new BehaviorSubject<boolean>(false);
  vendorCentralCredential =
    new BehaviorSubject<AdminVendorCentralCredential | null>(null);

  dispose = async () => {
    this.isFetching.next(false);
    this.vendorCentralCredentials.next([]);
  };

  disposeAccount = async () => {
    this.vendorCentralCredential.next(null);
    this.isFetching.next(false);
  };

  getVendorCentralCredentials = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        "/admin/vendor-central-credentials",
        {
          method: "GET",
        }
      );
      if (response?.ok) {
        this.vendorCentralCredentials.next(
          ((await response.json()) as AdminVendorCentralCredential[]).map((x) =>
            Object.assign(new AdminVendorCentralCredential(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getVendorCentralCredential = async (id: string) => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        "/admin/vendor-central-credentials/" + id,
        {
          method: "GET",
        }
      );
      if (!!response?.ok) {
        this.vendorCentralCredential.next(
          Object.assign(
            new AdminVendorCentralCredential(),
            await response.json()
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };
}
