export enum Permission {
  ManageUsers = "manage_users",
  ManageRoles = "manage_roles",
  ManageOrganizations = "manage_organizations",
  ManageDashboards = "manage_dashboards",
  ManageIntegration = "manage_integration",
  ManageDisputeInvoiceSettings = "manage_dispute_invoice_settings",
  ManageInvoiceStatus = "manage_invoice_status",
  ManageCreditMemoStatus = "manage_credit_memos_status",
  ManageAmazonVendorCentralCredentials = "manage_amazon_vendor_central_credentials",
  ManageRemittances = "manage_remittances",
  ManageInvoices = "manage_invoices",
  ManageDisputeInvoiceLogs = "manage_dispute_invoice_logs",
  ManageBilling = "manage_billing"
}

export const permissions = [
  {
    code: Permission.ManageUsers,
    description: "Manage Users",
  },
  {
    code: Permission.ManageRoles,
    description: "Manage Roles",
  },
  {
    code: Permission.ManageOrganizations,
    description: "Manage Organizations",
  },
  {
    code: Permission.ManageDashboards,
    description: "Manage Dashboards",
  },
  {
    code: Permission.ManageIntegration,
    description: "Manage Integration",
  },
  {
    code: Permission.ManageDisputeInvoiceSettings,
    description: "Manage Dispute Invoice Settings",
  },
  {
    code: Permission.ManageInvoiceStatus,
    description: "Manage Invoice Status",
  },
  {
    code: Permission.ManageCreditMemoStatus,
    description: "Manage Credit Memo Status",
  },
  {
    code: Permission.ManageAmazonVendorCentralCredentials,
    description: "Manage Amazon Vendor Central Credentials",
  },
  {
    code: Permission.ManageRemittances,
    description: "Manage Remittances",
  },
  {
    code: Permission.ManageInvoices,
    description: "Manage Invoices",
  },
  {
    code: Permission.ManageDisputeInvoiceLogs,
    description: "Manage Dispute Invoice Logs",
  },
  {
    code: Permission.ManageBilling,
    description: "Manage Billing",
  },
];
