import { AdminOrganizationService } from "./admin-organization.service";
import { AdminRoleService } from "./admin-role.service";
import { AdminUserService } from "./admin-user.service";
import { AuthService } from "./auth.service";
import { DashboardService } from "./dashboard.service";
import { IntegrationService } from "./integration.service";
import { DisputeInvoiceLogsService } from "./dispute-invoice/log.services";
import { OrganizationService } from "./organization.service";
import { RoleService } from "./role.service";
import { UserService } from "./user.service";
import { VendorCentralCredentialService } from "./vendor-central-credential.service";
import { RemittanceService } from "./remittance.service";
import { RequestService } from "./request.service";
import { MenuService } from "./menu.service";
import { InvoiceService } from "./invoice.service";
import { DisputeInvoiceSettingsService } from "./dispute-invoice/settings.services";
import { AdminVendorCentralCredentialService } from "./admin-vendor-central-account.service";
import { BillingService } from "./billing.service";

export const authService = new AuthService();
//admin settings
export const adminUserService = new AdminUserService();
export const adminRoleService = new AdminRoleService();
export const adminOrganizationService = new AdminOrganizationService();
// admin
export const adminVendorCentralCredentialService =
  new AdminVendorCentralCredentialService();
export const requestsService = new RequestService();

// settings
export const userService = new UserService();
export const roleService = new RoleService();
export const organizationService = new OrganizationService();
export const billingService = new BillingService();

export const menuService = new MenuService();
export const dashboardService = new DashboardService();
export const vendorCentralCredentialService =
  new VendorCentralCredentialService();
export const integrationService = new IntegrationService();

//data
export const remittanceService = new RemittanceService();
export const invoiceService = new InvoiceService();
export const disputeInvoiceLogsService = new DisputeInvoiceLogsService();
export const disputeInvoiceSettingsService =
  new DisputeInvoiceSettingsService();

authService.isLogged.subscribe((logged) => {
  if (logged) {
  } else {
    userService.dispose();
    roleService.dispose();
    // admin
    adminUserService.dispose();
    adminRoleService.dispose();
    adminOrganizationService.dispose();
    requestsService.dispose();
    // others
    dashboardService.dispose();
    vendorCentralCredentialService.dispose();
    remittanceService.dispose();
    invoiceService.dispose();
    disputeInvoiceLogsService.dispose();
    disputeInvoiceSettingsService.dispose();
  }
});
