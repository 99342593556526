import { Organization } from "./organization";

export class AdminRole {
  id: string = "";
  name: string = "";
  permissions: string[] = [];
  organization: Organization = new Organization();
}

export class Role {
  id: string = "";
  name: string = "";
  permissions: string[] = [];
}
