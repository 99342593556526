import { SyncOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  adminOrganizationService,
  adminVendorCentralCredentialService,
  requestsService,
} from "@/services";
import { useObservable } from "@/utils/use-observable";
import dayjs from "dayjs";
import {
  apiDateTimeFormat,
  dateFormat,
  renderDateTime,
  renderStatus,
  tokenSeparator,
} from "@/utils";
import "./index.less";
import { TableRef } from "antd/es/table";
import { Request } from "@/models/request";
import { ColumnsType } from "antd/lib/table";
import { RequestStatus, requestStatusList } from "@/models/enum/request-status";
import { requestTypeList } from "@/models/enum/request-type";
import { Organization } from "@/models/organization";

const { Title } = Typography;
const { Option } = Select;

export default function RequestsPage() {
  const items: CollapseProps["items"] = [
    {
      key: "filters",
      label: "Filters",
      children: <FilterForm />,
    },
  ];

  const columns: ColumnsType<Request> = [
    {
      title: "Creation Date",
      key: "date",
      dataIndex: "date",
      width: "150px",
      render: renderDateTime,
    },
    {
      title: "Request Id",
      key: "requestId",
      dataIndex: "requestId",
      width: "300px",
    },
    {
      title: "Organization",
      key: "organization",
      dataIndex: "organization",
      render: (value: Organization) => value?.name,
      width: "150px",
    },
    {
      title: "Account Name",
      key: "accountName",
      dataIndex: "accountName",
      width: "250px",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      width: "200px",
      render: renderStatus(requestTypeList),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "130px",
      render: renderStatus(requestStatusList),
    },
    {
      title: "Start Date",
      key: "startDate",
      dataIndex: "startDate",
      width: "150px",
      render: renderDateTime,
    },
    {
      title: "End Date",
      key: "endDate",
      dataIndex: "endDate",
      width: "150px",
      render: renderDateTime,
    },
    {
      title: "Execution time",
      key: "executionTime",
      dataIndex: "executionTime",
      width: "150px",
      align: "right",
      render: (value: string | null): string => {
        if (value) {
          const seconds = Math.trunc(+value / 1000000000);
          if (seconds >= 60) {
            const minutes = Math.trunc(seconds / 60);
            const discardedSeconds = seconds % 60;
            if (minutes >= 60) {
              const hours = Math.trunc(minutes / 60);
              const discardedMinutes = minutes % 60;
              return (
                hours + "h " + discardedMinutes + "m " + discardedSeconds + "s"
              );
            } else {
              return minutes + "m " + discardedSeconds + "s";
            }
          } else {
            return seconds + "s";
          }
        }
        return "-";
      },
    },
    {
      title: "Error message",
      key: "errorMessage",
      dataIndex: "errorMessage",
      width: "300px",
    },
    {
      title: "Action",
      key: "action",
      width: "70px",
      align: "center",
      render: (_: any, request: Request) => {
        return <ResendButton requestId={request.requestId} />;
      },
    },
  ];

  const expandedRowRender = (record: Request) => {
    return (
      <Row>
        <Col xs={12}>
          <Title level={4}>Request</Title>
          <pre>{JSON.stringify(record.requestData, null, 2)}</pre>
        </Col>
        {record.responseData && (
          <Col xs={12}>
            <Title level={4}>Response</Title>
            <pre>{JSON.stringify(record.responseData, null, 2)}</pre>
          </Col>
        )}
      </Row>
    );
  };

  const isFetching = useObservable(requestsService.isFetching);
  const requests = useObservable(requestsService.requests);
  var tableRef = useRef<TableRef>(null);

  const onScroll = (event: any) => {
    if (event.target) {
      let maxScroll = Math.round(
        event.target.scrollHeight - event.target.clientHeight
      );
      let currentScroll = Math.round(event.target.scrollTop);
      if (currentScroll >= maxScroll - 100) {
        requestsService.next();
      }
    }
  };

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const tbody =
        tableRef.current.nativeElement.querySelector(".ant-table-body");
      if (tbody) {
        tbody.addEventListener("scroll", onScroll);

        return () => {
          tbody.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, []);

  useEffect(() => {
    const subscribe = requestsService.filters.subscribe(() => {
      tableRef.current?.nativeElement
        .querySelector(".ant-table-body")
        ?.scrollTo({ top: 0 });
      requestsService.getRequests();
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const onRefresh = () => {
    requestsService.refresh();
  };

  const onResend = () => {
    requestsService.resendFromFilter();
  }

  return (
    <div className="request-page">
      <Title level={3}>Requests</Title>
      <Row className="actions" justify={"space-between"}>
        <Space>
          <Button icon={<SyncOutlined spin={isFetching} />} onClick={onRefresh}>
            Refresh
          </Button>
          <Button icon={<SyncOutlined />} onClick={onResend}>
            Resend
          </Button>
        </Space>
      </Row>
      <Collapse
        className="filters"
        size="small"
        bordered={false}
        items={items}
      />
      <Table
        ref={tableRef}
        rowClassName={(log) =>
          log.status === RequestStatus.FAILED ? "table-row-error" : ""
        }
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={requests}
        rowKey="requestId"
        size="small"
        pagination={false}
        scroll={{ y: "calc(100vh - 233px)" }}
      />
    </div>
  );
}

function FilterForm() {
  const filters = useObservable(requestsService.filters);
  const allAccounts = useObservable(
    adminVendorCentralCredentialService.allAccounts
  );
  const organizations = useObservable(adminOrganizationService.organizations);

  useEffect(() => {
    adminVendorCentralCredentialService.getVendorCentralCredentials();
    adminOrganizationService.getOrganizations();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.requestIds}
          onChange={requestsService.setRequestIds}
          placeholder="Request Ids"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.dateFrom ? dayjs(filters.dateFrom, apiDateTimeFormat) : null
          }
          onChange={requestsService.setDateFrom}
          placeholder="Date from"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.dateTo ? dayjs(filters.dateTo, apiDateTimeFormat) : null
          }
          onChange={requestsService.setDateTo}
          placeholder="Date to"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.organizationIds}
          onChange={requestsService.setOrganizationIds}
          placeholder="Organizations"
          allowClear
          style={{ width: "100%" }}
          mode="multiple"
        >
          {organizations.map((organization) => (
            <Option key={organization.id} value={organization.id}>
              {organization.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={allAccounts?.filter((account) =>
            !filters.organizationIds
              ? true
              : filters.organizationIds.includes(account.organizationId)
          )}
          optionFilterProp="label"
          value={filters.accountNames}
          onChange={requestsService.setAccountNames}
          placeholder="Account Names"
          allowClear
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={requestTypeList}
          value={filters.types}
          onChange={requestsService.setTypes}
          allowClear
          mode="multiple"
          placeholder="Types"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={requestStatusList}
          value={filters.statuses}
          onChange={requestsService.setStatuses}
          allowClear
          mode="multiple"
          placeholder="Statuses"
          style={{ width: "100%" }}
        />
      </Col>
    </Row>
  );
}

function ResendButton(props: { requestId: string }) {
  const [isResending, setIsResending] = useState(false);

  const onClick = () => {
    setIsResending(true);
    requestsService.resend(props.requestId).then(async (response) => {
      if (response) {
        await requestsService.refresh();
      }
      setIsResending(false);
    });
  };

  return (
    <Tooltip title="Resend request">
      <Button
        icon={<SyncOutlined spin={isResending} />}
        onClick={!isResending ? onClick : undefined}
      />
    </Tooltip>
  );
}
