import { Organization } from "@/models/organization";
import { authService } from ".";
import { BehaviorSubject } from "rxjs";

export class OrganizationService {
  organization = new BehaviorSubject<Organization | null>(null);
  isFetching = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);

  getOrganization = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch("/organizations/current", {
        method: "GET",
      });
      if (!!response?.ok) {
        this.organization.next(
          Object.assign(new Organization(), await response.json())
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  updateOrganization = async (name: string) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch("/organizations", {
        method: "PATCH",
        body: JSON.stringify({ name }),
      });

      if (!!response?.ok) {
        this.organization.next(
          Object.assign(this.organization.value!!, { name })
        );
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };
}
