import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import "./index.less";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import { useEffect, useRef } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import useDimension from "@/utils/use-dimension";
import { Widget } from "./widget";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  StarFilled,
  SettingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { useParams } from "react-router-dom";

const { Title } = Typography;

export default function DashboardPage() {
  const dashboardId = useParams().id!!;
  const dashboard = useObservable(dashboardService.dashboard);

  useEffect(() => {
    dashboardService.getDashboard(dashboardId);

    return () => {
      dashboardService.disposeDashboard();
    };
  }, [dashboardId]);

  if (!dashboard) {
    return <Loader />;
  }

  return (
    <div className="dashboard-page">
      <DashboardTitle />
      <Divider />
      <Layout />
    </div>
  );
}

function DashboardTitle() {
  const dashboard = useObservable(dashboardService.dashboard)!;
  const isEditing = useObservable(dashboardService.isEditing);

  const onEdit = () => {
    dashboardService.editing();
  };

  const onCancel = () => {
    dashboardService.cancelEditing();
  };

  const setFavorite = () => {
    dashboardService.setFavorite(
      dashboard.id,
      !(dashboard.settings?.favorite ?? false),
      true
    );
  };

  return (
    <Space>
      <Button
        icon={
          <StarFilled
            style={{
              color: dashboard.settings?.favorite ? "gold" : "grey",
              fontSize: "25px",
            }}
          />
        }
        onClick={setFavorite}
        shape="circle"
        type="text"
      />
      {isEditing && (
        <Input defaultValue={dashboard.name} style={{ fontSize: "24px" }} />
      )}
      {!isEditing && (
        <Title level={3} style={{ margin: 0 }}>
          {dashboard.name}
        </Title>
      )}
      {!isEditing && (
        <Button icon={<EditOutlined />} onClick={onEdit} shape="circle" />
      )}
      {isEditing && (
        <Space>
          <Button type="primary" icon={<SaveOutlined />} shape="circle" />
          <Button icon={<CloseOutlined />} onClick={onCancel} shape="circle" />
        </Space>
      )}
    </Space>
  );
}

function Layout() {
  const divRef = useRef(null);
  const { width } = useDimension(divRef);
  const isEditing = useObservable(dashboardService.isEditing);
  const widgets = useObservable(dashboardService.widgets);

  return (
    <>
      <Filters />
      <div ref={divRef} style={!widgets ? { height: 200 } : {}}>
        {widgets && width > 0 && (
          <ResponsiveGridLayout
            cols={{ lg: 24, md: 18, sm: 12, xs: 8, xxs: 4 }}
            rowHeight={10}
            isDraggable={isEditing}
            isResizable={isEditing}
            width={width}
            draggableCancel=".settings"
          >
            {widgets.map((widget, index) => {
              return (
                <div
                  className="widget"
                  key={index}
                  data-grid={widget.position}
                >
                  <Widget
                    name={widget.name}
                    settings={widget.settings}
                    type={widget.type}
                    value={widget.value}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        )}
      </div>
    </>
  );
}

function Filters() {
  const filters = useObservable(dashboardService.filters);

  return (
    filters && (
      <Row gutter={[16, 16]}>
        {filters.map((filter) => (
          <Col xl={3} md={8} sm={12} xs={24} key={filter.field}>
            <DashboardFilter
              name={filter.name}
              multiValue={filter.multiValue}
              options={filter.value.options}
              initialValue={filter.value.initialValue}
              value={filter.value.initialValue}
            />
          </Col>
        ))}
        <Col xl={3} md={8} sm={12} xs={24}>
          <Space>
            <Tooltip title="Filter settings">
              <Button icon={<SettingOutlined />} shape="circle" />
            </Tooltip>
            <Tooltip title="Add Widget">
              <Button icon={<PlusOutlined />} shape="circle" />
            </Tooltip>
          </Space>
        </Col>
      </Row>
    )
  );
}

function DashboardFilter(props: {
  name: string;
  multiValue: boolean;
  options?: any;
  initialValue?: any;
  value: any;
}) {
  return (
    <Select
      options={props.options}
      defaultValue={props.initialValue}
      onChange={(value) => dashboardService.setFilter(props.name, value)}
      placeholder={props.name}
      allowClear
      style={{ width: "100%" }}
      mode={
        props.multiValue
          ? props.options
            ? "multiple"
            : "tags"
          : undefined
      }
    />
  );
}
