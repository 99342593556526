import {
  Table,
  Typography,
} from "antd";
import { useEffect } from "react";
import { billingService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import "./index.less";
import { renderCurrency, renderDate, renderDateTime, renderPercentage, renderStatus } from "@/utils";
import { internalInternalInvoiceStatusList } from "@/models/enum/intenral-invoice-status";
import { InternalInvoice, InternalInvoiceItem } from "@/models/internal-invoice";
import { RemittanceType } from "@/models/enum/remittance-type";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

export default function InternalInvoicesPage() {
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: "150px",
      render: renderDateTime,
    },
    {
      title: "Reference",
      key: "yearMonth",
      dataIndex: "yearMonth",
      width: "100px"
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "100px",
      render: renderStatus(internalInternalInvoiceStatusList)
    },
    {
      title: "Recovered Amount",
      key: "recoveredAmount",
      dataIndex: "recoveredAmount",
      width: "150px",
      render: renderCurrency,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: "150px",
      render: renderCurrency,
    },
  ];

  const invoices = useObservable(billingService.invoices);

  useEffect(() => {
    billingService.getInvoices();
  }, []);

  const expandedRowRender = (record: InternalInvoice) => {
    return (
      <div className="expanded-row-block">
        <InternalInvoiceDetails
          invoice={record}
        />
      </div>
    );
  };

  return (
    <div className="internal-invoices-page">
      <Title level={3}>Billing</Title>
      <Table
        columns={columns}
        dataSource={invoices}
        expandable={{ expandedRowRender }}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
        scroll={{ x: 1000 }}
      />
    </div>
  );
}

function InternalInvoiceDetails(props: { invoice: InternalInvoice }) {
  const columns = [
    {
      title: "Payee Code",
      key: "payeeCode",
      dataIndex: "payeeCode",
      width: "100px"
    },
    {
      title: "Account Name",
      key: "accountName",
      dataIndex: "accountName",
      width: "200px",
    },
    {
      title: "Remittance Number",
      key: "remittanceNumber",
      dataIndex: "remittanceNumber",
      width: "100px",
      render: (value: string) => (<Link to={`/remittances/${value}`}>
        {value}
      </Link>)
    },
    {
      title: "Invoice Number",
      key: "invoiceNumber",
      dataIndex: "invoiceNumber",
      width: "100px",
      render: (value: string, row: InternalInvoiceItem) => {
        switch (row.type) {
          case RemittanceType.INVOICE:
            return (
              <Link to={`/invoices/${row.payeeCode}/${value}`}>
                {value}
              </Link>
            );
          case RemittanceType.SC:
          case RemittanceType.SCR:
          case RemittanceType.PC:
          case RemittanceType.PCR:
            const pureInvoiceNumber = value.replace(/(SC|SCR|PC|PCR)+$/g, "")
            return (
              <Link to={`/invoices/${row.payeeCode}/${pureInvoiceNumber}`}>
                {value}
              </Link>
            );
          default:
            return <Text>{value}</Text>;
        }
      }
    },
    {
      title: "Invoice Date",
      key: "invoiceDate",
      dataIndex: "invoiceDate",
      width: "70px",
      render: renderDate,
    },
    {
      title: "Payment Due Date",
      key: "paymentDueDate",
      dataIndex: "paymentDueDate",
      width: "70px",
      render: renderDate,
    },
    {
      title: "Fee",
      key: "fee",
      dataIndex: "fee",
      width: "70px",
      render: renderPercentage,
    },
    {
      title: "Recovered Amount",
      key: "recoveredAmount",
      dataIndex: "recoveredAmount",
      width: "100px",
      render: renderCurrency,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: "100px",
      render: renderCurrency,
    },
  ];

  return (
    <>
      <Title level={4}>Items</Title>
      <Table
        columns={columns}
        dataSource={props.invoice.items}
        rowKey="invoiceNumber"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
      />
    </>
  );
}
