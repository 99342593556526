import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Organization } from "@/models/organization";
import { adminOrganizationService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import "./index.less";

const { Search } = Input;

export default function AdminOrganizationsPage() {
  const columns = [
    {
      title: "Name",
      key: "name",
      width: "200px",
      sorter: {
        compare: (a: Organization, b: Organization) =>
          a.name > b.name ? -1 : 1,
        multiple: 3,
      },
      render: (_: string, row: Organization) => (
        <Link to={row.id}>{row.name}</Link>
      ),
    },
  ];

  const isFetching = useObservable(adminOrganizationService.isFetching);
  const organizations = useObservable(adminOrganizationService.organizations);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    adminOrganizationService.getOrganizations();
  }, []);

  const onRefresh = () => {
    adminOrganizationService.refresh();
  };

  const onSearch = (value: string) => {
    setSearchText(value.toLowerCase());
  };

  return (
    <div className="organizations-page">
      <Row className="actions" justify="start" gutter={[8, 8]}>
        <Col className="action" sm={8} xs={24}>
          <AddOrganizationButton />
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Button
            style={{ width: "100%" }}
            icon={<SyncOutlined spin={isFetching} />}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search organization"
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <Table
        style={{ maxWidth: "800px" }}
        columns={columns}
        dataSource={organizations.filter((organization) =>
          organization.name.toLowerCase().includes(searchText)
        )}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
        scroll={{ x: 200 }}
      />
    </div>
  );
}

function AddOrganizationButton() {
  const [newOrganizationVisible, setNewOrganizationVisible] = useState(false);

  const showNewOrganizationModal = () => {
    setNewOrganizationVisible(true);
  };

  const hideNewOrganizationModal = (added: boolean) => {
    setNewOrganizationVisible(false);
    if (added) {
      adminOrganizationService.getOrganizations();
    }
  };

  return (
    <>
      <Button
        style={{ width: "100%" }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={showNewOrganizationModal}
      >
        New Organization
      </Button>
      <OrganizationNewForm
        visible={newOrganizationVisible}
        onFinish={hideNewOrganizationModal}
      />
    </>
  );
}

function OrganizationNewForm(props: {
  visible: boolean;
  onFinish: (added: boolean) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isAdding = useObservable(adminOrganizationService.isAdding);

  const onSubmit = ({ name }: { name: string }) => {
    adminOrganizationService.addOrganization(name).then((response) => {
      if (response) {
        message.success("Organization added", 4);
        props.onFinish(true);
        form.resetFields();
      }
    });
  };

  const onCancel = () => {
    props.onFinish(false);
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={onCancel}
      title="New Organization"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAdding}
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
