import { BehaviorSubject } from "rxjs";
import { authService } from "..";
import { DisputeInvoiceLog } from "@/models/dispute-invoice-log";
import { DisputeInvoiceLogFilters } from "@/filters/dispute-invoice-log";
import { Dayjs } from "dayjs";
import {apiDateTimeFormat, downloadFileFromApiResponse} from "@/utils";
import { DisputeInvoiceAction } from "@/models/enum/dispute-invoice-action";
import { DisputeInvoiceStatus } from "@/models/enum/dispute-invoice-status";

export class DisputeInvoiceLogsService {
  isFetching = new BehaviorSubject(false);
  logs = new BehaviorSubject<DisputeInvoiceLog[]>([]);
  filters = new BehaviorSubject<DisputeInvoiceLogFilters>(
    new DisputeInvoiceLogFilters()
  );
  pageLoaded = [0];
  page = 0;
  limit = 100;

  dispose = async () => {
    this.logs.next([]);
    this.filters.next(new DisputeInvoiceLogFilters());
    this.page = 0;
  };

  getLogs = async () => {
    const logs = await this._getLogs(0, this.limit);
    if (logs) {
      this.pageLoaded = [0];
      this.logs.next(logs);
    }
  };

  exportLogs = async () => {
    await this._exportLogs()
  }

  refresh = async () => {
    const logs = await this._getLogs(0, (this.page + 1) * this.limit, false);
    if (logs) {
      this.logs.next(logs);
    }
  };

  next = async () => {
    if (!this.pageLoaded.includes(this.page + 1)) {
      this.pageLoaded.push(this.page + 1);
      const logs = await this._getLogs(this.page + 1, this.limit);
      if (!logs) {
        this.pageLoaded.pop();
      } else if (logs.length > 0) {
        this.logs.next([...this.logs.value, ...logs]);
      }
    }
  };

  _getLogs = async (page: number, limit: number, savePage: boolean = true) => {
    try {
      this.isFetching.next(true);

      const response = await authService.authFetch(
        `/dispute-invoices/logs?page=${page}&limit=${limit}`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );
      if (response?.ok) {
        const logs = ((await response.json()) as DisputeInvoiceLog[]).map((x) =>
          Object.assign(new DisputeInvoiceLog(), x)
        );
        if (savePage && logs.length > 0) {
          this.page = page;
        }
        return logs;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
    return null;
  };

  _exportLogs = async () => {
    try {
      const response = await authService.authFetch(
          `/dispute-invoices/logs/export`,
          {
            method: "POST",
            body: JSON.stringify(this.filters.value),
          }
      );

      if (response?.ok) {
        await downloadFileFromApiResponse(response);
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  setDateFrom = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateFrom = date?.format(apiDateTimeFormat);
    this.filters.next(filters);
  };

  setDateTo = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateTo = date?.format(apiDateTimeFormat);
    this.filters.next(filters);
  };

  setAccountNames = async (value: string[]) => {
    const filters = this.filters.value;
    filters.accountNames = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setActions = async (value: DisputeInvoiceAction[]) => {
    const filters = this.filters.value;
    filters.actions = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setStatuses = async (value: DisputeInvoiceStatus[]) => {
    const filters = this.filters.value;
    filters.statuses = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setInvoiceNumbers = async (value: string[]) => {
    const filters = this.filters.value;
    filters.invoiceNumbers = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setDisputeIds = async (value: string[]) => {
    const filters = this.filters.value;
    filters.disputeIds = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setCaseIds = async (value: string[]) => {
    const filters = this.filters.value;
    filters.caseIds = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };
}
