import {CloudDownloadOutlined, SyncOutlined} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef } from "react";
import {
  disputeInvoiceLogsService,
  vendorCentralCredentialService,
} from "@/services";
import { useObservable } from "@/utils/use-observable";
import dayjs from "dayjs";
import {
  apiDateTimeFormat,
  dateTimeFormat,
  renderDateTime,
  renderStatus,
  tokenSeparator,
} from "@/utils";
import { disputeInvoiceActionsList } from "@/models/enum/dispute-invoice-action";
import {
  DisputeInvoiceStatus,
  disputeInvoiceStatusList,
} from "@/models/enum/dispute-invoice-status";
import "./index.less";
import { TableRef } from "antd/es/table";

const { Title } = Typography;

export default function DisputeInvoiceLogsPage() {
  const items: CollapseProps["items"] = [
    {
      key: "filters",
      label: "Filters",
      children: <FilterForm />,
    },
  ];

  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: "150px",
      render: renderDateTime,
    },
    {
      title: "Account Name",
      key: "accountName",
      dataIndex: "accountName",
      width: "250px",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: "150px",
      render: renderStatus(disputeInvoiceActionsList),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "100px",
      render: renderStatus(disputeInvoiceStatusList),
    },
    {
      title: "Invoice number",
      key: "invoiceNumber",
      dataIndex: "invoiceNumber",
      width: "150px",
    },
    {
      title: "Dispute ID",
      key: "disputeId",
      dataIndex: "disputeId",
      width: "150px",
    },
    {
      title: "Case ID",
      key: "caseId",
      dataIndex: "caseId",
      width: "150px",
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
      width: "150px",
    },
  ];

  const isFetching = useObservable(disputeInvoiceLogsService.isFetching);
  const logs = useObservable(disputeInvoiceLogsService.logs);
  var tableRef = useRef<TableRef>(null);

  const onScroll = (event: any) => {
    if (event.target) {
      let maxScroll = Math.round(
        event.target.scrollHeight - event.target.clientHeight
      );
      let currentScroll = Math.round(event.target.scrollTop);
      if (currentScroll >= maxScroll - 100) {
        disputeInvoiceLogsService.next();
      }
    }
  };

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const tbody =
        tableRef.current.nativeElement.querySelector(".ant-table-body");
      if (tbody) {
        tbody.addEventListener("scroll", onScroll);

        return () => {
          tbody.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, []);

  useEffect(() => {
    const subscribe = disputeInvoiceLogsService.filters.subscribe(() => {
      tableRef.current?.nativeElement
        .querySelector(".ant-table-body")
        ?.scrollTo({ top: 0 });
      disputeInvoiceLogsService.getLogs();
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const onRefresh = () => {
    disputeInvoiceLogsService.getLogs();
  };

  const onExportCsv = () => {
    disputeInvoiceLogsService.exportLogs();
  };

  return (
    <div className="logs-page">
      <Title level={3}>Dispute Invoice Logs</Title>
      <Row className="actions" justify={"space-between"}>
        <Space>
          <Button icon={<SyncOutlined spin={isFetching} />} onClick={onRefresh}>
            Refresh
          </Button>
          <Button icon={<CloudDownloadOutlined />} onClick={onExportCsv}>
            Export
          </Button>
        </Space>
      </Row>
      <Collapse
        className="filters"
        size="small"
        bordered={false}
        items={items}
      />
      <Table
        ref={tableRef}
        rowClassName={(log) =>
          log.status === DisputeInvoiceStatus.ERROR ? "table-row-error" : ""
        }
        columns={columns}
        dataSource={logs}
        rowKey="id"
        size="small"
        pagination={false}
        scroll={{ y: "calc(100vh - 233px)" }}
      />
    </div>
  );
}

function FilterForm() {
  const filters = useObservable(disputeInvoiceLogsService.filters);
  const allAccounts = useObservable(vendorCentralCredentialService.allAccounts);

  useEffect(() => {
    vendorCentralCredentialService.getVendorCentralCredentials();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateTimeFormat()}
          showTime
          value={
            filters.dateFrom ? dayjs(filters.dateFrom, apiDateTimeFormat) : null
          }
          onChange={disputeInvoiceLogsService.setDateFrom}
          placeholder="Date from"
          style={{ width: "100%" }}
          allowClear
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateTimeFormat()}
          showTime
          value={
            filters.dateTo ? dayjs(filters.dateTo, apiDateTimeFormat) : null
          }
          onChange={disputeInvoiceLogsService.setDateTo}
          placeholder="Date to"
          style={{ width: "100%" }}
          allowClear
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={allAccounts}
          optionFilterProp="label"
          value={filters.accountNames}
          onChange={disputeInvoiceLogsService.setAccountNames}
          placeholder="Account Names"
          allowClear
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={disputeInvoiceActionsList}
          value={filters.actions}
          onChange={disputeInvoiceLogsService.setActions}
          allowClear
          placeholder="Actions"
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={disputeInvoiceStatusList}
          value={filters.statuses}
          onChange={disputeInvoiceLogsService.setStatuses}
          allowClear
          placeholder="Statuses"
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.invoiceNumbers}
          onChange={disputeInvoiceLogsService.setInvoiceNumbers}
          allowClear
          placeholder="Invoice Numbers"
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.disputeIds}
          onChange={disputeInvoiceLogsService.setDisputeIds}
          placeholder="Dispute Ids"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.caseIds}
          onChange={disputeInvoiceLogsService.setCaseIds}
          placeholder="Case Ids"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
    </Row>
  );
}
