// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded-row-block {
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/billing/internal-invoices/index.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ","sourcesContent":[".expanded-row-block {\n    margin: 20px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
