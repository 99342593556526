import { BehaviorSubject } from "rxjs";
import { authService } from ".";
import { InternalInvoice } from "@/models/internal-invoice";

export class BillingService {
  invoices = new BehaviorSubject<InternalInvoice[]>([]);
  // filters = new BehaviorSubject<InvoiceFilters>(new InvoiceFilters());
  isFetching = new BehaviorSubject(false);
  pageLoaded = [0];
  page = 0;
  limit = 10;

  dispose = async () => {
    this.invoices.next([]);
    // this.filters.next(new InvoiceFilters());
    this.page = 0;
  };


  getInvoices = async () => {
    const invoices = await this._getInvoices(0, this.limit);
    if (invoices) {
      this.pageLoaded = [0];
      this.invoices.next(invoices);
    }
  };

  _getInvoices = async (
    page: number,
    limit: number,
    savePage: boolean = true
  ) => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        `/billing/invoices?page=${page}&limit=${limit}`,
        {
          method: "POST",
          // body: JSON.stringify(this.filters.value),
        }
      );
      if (response?.ok) {
        const invoices = ((await response.json()) as InternalInvoice[]).map((invoice) =>
          Object.assign(new InternalInvoice(), invoice)
        );
        if (savePage && invoices.length > 0) {
          this.page = page;
        }
        return invoices;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
    return null;
  };

}
