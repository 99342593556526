import "./index.less";
import InternalInvoicesPage from "../billing/internal-invoices";

export default function BillingPage() {
  return (
    <div className="billing-page">
      <InternalInvoicesPage />
    </div>
  );
}
