import { Col, Divider, Row, Table, TableColumnsType, Typography } from "antd";
import { useObservable } from "@/utils/use-observable";
import { renderCurrency, renderDate, renderStatus } from "@/utils";
import "./index.less";
import { Remittance, RemittanceDetail } from "@/models/remittance";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "@/app/components/loader";
import { remittanceService } from "@/services";
import { remittanceStatusList } from "@/models/enum/remittance-status";
import { RemittanceType, remittanceTypeList } from "@/models/enum/remittance-type";

const { Title, Text } = Typography;

export default function RemittancePage() {
  const isFetching = useObservable(remittanceService.isFetchingOne);
  const remittance = useObservable(remittanceService.remittance);
  const accountName = useParams().accountName!!;
  const number = useParams().number!!;

  useEffect(() => {
    remittanceService.getRemittance(number);

    return () => {
      remittanceService.disposeRemittance();
    };
  }, [accountName, number]);

  if (isFetching || remittance == null) {
    return <Loader />;
  }

  return (
    <div className="remittance-page">
      <Title level={3}>Remittance {remittance.number}</Title>
      <Title level={5}>{remittance.accountName}</Title>
      <Divider />
      <Row className="remittance-details">
        <Col flex={1}>
          <Row>
            <Col className="label-column">
              <Text strong>Remittance Date:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderDate(remittance.date)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Status:</Text>
            </Col>
            <Col className="value-column">
              <Text>
                {renderStatus(remittanceStatusList)(remittance.status)}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col flex={1}>
          <Row>
            <Col className="label-column">
              <Text strong>Currency:</Text>
            </Col>
            <Col className="value-column">
              <Text>{remittance.currency}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Payment Type:</Text>
            </Col>
            <Col className="value-column">
              <Text>{remittance.paymentType}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <RemittanceDetails remittance={remittance} />
    </div>
  );
}

function RemittanceDetails(props: { remittance: Remittance }) {
  const remittanceDetailColumns: TableColumnsType<RemittanceDetail> = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: "150px",
      render: (value: string, row: RemittanceDetail) => {
        switch (row.type) {
          case RemittanceType.INVOICE:
            return (
              <Link to={`/invoices/${props.remittance.payeeCode}/${value}`}>
                {value}
              </Link>
            );
          case RemittanceType.SC:
          case RemittanceType.SCR:
          case RemittanceType.PC:
          case RemittanceType.PCR:
            const pureInvoiceNumber = value.replace(/(SC|SCR|PC|PCR)+$/g, "")
            return (
              <Link to={`/invoices/${props.remittance.payeeCode}/${pureInvoiceNumber}`}>
                {value}
              </Link>
            );
          default:
            return <Text>{value}</Text>;
        }
      }
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "150px",
      render: renderStatus(remittanceTypeList),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "250px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      width: "150px",
      render: renderCurrency,
    },
    {
      title: "Net Amount Paid",
      dataIndex: "netAmountPaid",
      key: "netAmountPaid",
      align: "right",
      width: "150px",
      render: renderCurrency,
    },
    {
      title: "Remaining Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      align: "right",
      width: "150px",
      render: renderCurrency,
    },
  ];

  return (
    <>
      <Title level={4}>Details</Title>
      <Table
        columns={remittanceDetailColumns}
        dataSource={props.remittance.details}
        pagination={false}
        rowKey={(row: RemittanceDetail) => row.invoiceNumber!!}
        size="small"
        scroll={{ x: 1100 }}
      />
    </>
  );
}
