export enum InternalInvoiceStatus {
  CREATED = "CREATED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PAID = "PAID"
}

export const internalInternalInvoiceStatusList = [
  {
    value: InternalInvoiceStatus.CREATED,
    label: "Created",
    color: "darkorange",
  },
  {
    value: InternalInvoiceStatus.PENDING,
    label: "Pending",
    color: "darkorange",
  },
  {
    value: InternalInvoiceStatus.PROCESSING,
    label: "Processing",
    color: "darkorange",
  },
  { value: InternalInvoiceStatus.PAID, label: "Paid", color: "green", },
];
