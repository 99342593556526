// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reset-password-page .logo {
  text-align: center;
}
.reset-password-page .title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
}
.reset-password-page .fixed-width-page {
  width: 500px;
}
@media (max-width: 767px) {
  .reset-password-page .fixed-width-page {
    width: 80vw;
  }
}
.reset-password-page .alert {
  margin-bottom: 25px !important;
}
.reset-password-page .content {
  background: white;
  padding: 25px;
}
.reset-password-page .row-link {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/reset-password/index.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AALA;EAOI,kBAAA;AACJ;AARA;EAWI,kBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAbA;EAiBI,YAAA;AADJ;AAIE;EAAA;IAEI,WAAA;EAFJ;AACF;AArBA;EA2BI,8BAAA;AAHJ;AAxBA;EA+BI,iBAAA;EACA,aAAA;AAJJ;AA5BA;EAoCI,kBAAA;AALJ","sourcesContent":[".reset-password-page {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .logo {\n    text-align: center;\n  }\n\n  .title {\n    text-align: center;\n    margin-top: 0;\n    margin-bottom: 25px;\n  }\n\n  .fixed-width-page {\n    width: 500px;\n  }\n\n  @media (max-width: 767px) {\n    .fixed-width-page {\n      width: 80vw;\n    }\n  }\n\n  .alert {\n    margin-bottom: 25px !important;\n  }\n\n  .content {\n    background: white; // @component-background;\n    padding: 25px;\n  }\n\n  .row-link {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
