import { Button, Divider, Form, Input, message, Row, Typography } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminOrganizationService } from "@/services";
import Loader from "@app/components/loader";
import { useObservable } from "@/utils/use-observable";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function AdminOrganizationPage() {
  const [form] = Form.useForm();
  const organization = useObservable(adminOrganizationService.organization);
  const isFetching = useObservable(adminOrganizationService.isFetchingOne);
  const isUpdating = useObservable(adminOrganizationService.isUpdating);
  const isDeleting = useObservable(adminOrganizationService.isDeleting);
  const router = useNavigate();
  const organizationId = useParams().id!!;
  const [t] = useTranslation();

  useEffect(() => {
    adminOrganizationService.getOrganization(organizationId);

    return () => {
      adminOrganizationService.disposeOrganization();
    };
  }, [organizationId]);

  const onSubmit = ({ name }: { name: string }) => {
    adminOrganizationService
      .updateOrganization(organizationId, name)
      .then((response) => {
        if (response) {
          message.success("Organization updated", 4);
        }
      });
  };

  const onDelete = () => {
    adminOrganizationService
      .deleteOrganization(organizationId)
      .then((response) => {
        if (response) {
          message.success("Organization deleted", 4);
          router("/admin/settings/organizations");
        }
      });
  };

  if (!organization || isFetching) return <Loader />;

  return (
    <Row justify="center">
      <div style={{ width: "350px" }}>
        <Row justify="center">
          <Title level={3}>{organization.name}</Title>
        </Row>
        <Divider />
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            initialValue={organization.name}
            label="Name"
            name="name"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" block loading={isUpdating} htmlType="submit">
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              danger
              loading={isDeleting}
              onClick={onDelete}
            >
              Delete
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Row>
  );
}
