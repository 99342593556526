import { BehaviorSubject } from "rxjs";
import { authService } from ".";
import { CreditMemo, Invoice } from "@/models/invoice";
import { Dayjs } from "dayjs";
import { apiDateFormat, downloadFileFromApiResponse } from "@/utils";
import { InvoiceFilters } from "@/filters/invoice";
import { InvoiceStatus } from "@/models/enum/invoice-status";
import { CreditMemoStatus } from "@/models/enum/credit-memo-status";
import { VarianceStatus } from "@/models/enum/variance-status";
import { CaseStatus } from "@/models/enum/case-status";

export class InvoiceService {
  invoices = new BehaviorSubject<Invoice[]>([]);
  filters = new BehaviorSubject<InvoiceFilters>(new InvoiceFilters());
  isFetching = new BehaviorSubject(false);
  pageLoaded = [0];
  page = 0;
  limit = 100;
  invoice = new BehaviorSubject<Invoice | null>(null);
  isFetchingOne = new BehaviorSubject(false);

  dispose = async () => {
    this.invoices.next([]);
    this.filters.next(new InvoiceFilters());
    this.page = 0;
  };

  disposeInvoice = async () => {
    this.invoice.next(null);
  };

  getInvoices = async () => {
    const invoices = await this._getInvoices(0, this.limit);
    if (invoices) {
      this.pageLoaded = [0];
      this.invoices.next(invoices);
    }
  };

  exportInvoices = async () => {
    try {
      const response = await authService.authFetch(
        `/invoices/export`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );

      if (response?.ok) {
        await downloadFileFromApiResponse(response);
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  refresh = async () => {
    const invoices = await this._getInvoices(
      0,
      (this.page + 1) * this.limit,
      false
    );
    if (invoices) {
      this.invoices.next(invoices);
    }
  };

  next = async () => {
    if (!this.pageLoaded.includes(this.page + 1)) {
      this.pageLoaded.push(this.page + 1);
      const invoices = await this._getInvoices(this.page + 1, this.limit);
      if (!invoices) {
        this.pageLoaded.pop();
      } else if (invoices.length > 0) {
        this.invoices.next([...this.invoices.value, ...invoices]);
      }
    }
  };

  _getInvoices = async (
    page: number,
    limit: number,
    savePage: boolean = true
  ) => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        `/invoices?page=${page}&limit=${limit}`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );
      if (response?.ok) {
        const invoices = ((await response.json()) as Invoice[]).map((invoice) =>
          Object.assign(new Invoice(), invoice)
        );
        if (savePage && invoices.length > 0) {
          this.page = page;
        }
        return invoices;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
    return null;
  };

  setNumbers = async (value: string[]) => {
    const filters = this.filters.value;
    filters.numbers = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setDateFrom = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateFrom = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setDateTo = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateTo = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setAccountNames = async (value: string[]) => {
    const filters = this.filters.value;
    filters.accountNames = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setVCStatuses = async (value: InvoiceStatus[]) => {
    const filters = this.filters.value;
    filters.vendorCentralStatuses = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setPayees = async (value: string[]) => {
    const filters = this.filters.value;
    filters.payeeCodes = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setPaymentDueDateFrom = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.paymentDueDateFrom = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setPaymentDueDateTo = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.paymentDueDateTo = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setHasClaims = async (value?: boolean) => {
    const filters = this.filters.value;
    filters.hasClaims = value;
    this.filters.next(filters);
  };

  setDisputeIds = async (value?: string[]) => {
    const filters = this.filters.value;
    filters.disputeIds = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setCaseIds = async (value?: string[]) => {
    const filters = this.filters.value;
    filters.caseIds = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setCaseStatuses = async (value?: CaseStatus[]) => {
    const filters = this.filters.value;
    filters.caseStatuses = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setCreditMemoNumbers = async (value?: string[]) => {
    const filters = this.filters.value;
    filters.creditMemoNumbers = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setCreditMemoStatuses = async (value?: CreditMemoStatus[]) => {
    const filters = this.filters.value;
    filters.creditMemoStatuses = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setPurchaseQuantityVarianceStatuses = async (value?: VarianceStatus[]) => {
    const filters = this.filters.value;
    filters.purchaseQuantityVarianceStatuses =
      value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setPurchasePriceVarianceStatuses = async (value?: VarianceStatus[]) => {
    const filters = this.filters.value;
    filters.purchasePriceVarianceStatuses =
      value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setEans = async (value?: string[]) => {
    const filters = this.filters.value;
    filters.eans = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setAsins = async (value?: string[]) => {
    const filters = this.filters.value;
    filters.asins = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setPurcahseOrders = async (value?: string[]) => {
    const filters = this.filters.value;
    filters.purchaseOrders = value?.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  getInvoice = async (payeeCode: string, number: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}`,
        {
          method: "GET",
        }
      );
      if (response?.ok) {
        this.invoice.next(Object.assign(new Invoice(), await response.json()));
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  setClosedStatus = async (
    payeeCode: string,
    number: string,
    date: string | undefined,
    paymentDueDate: string,
    amount: number,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/closed`,
        {
          method: "POST",
          body: JSON.stringify({
            date,
            paymentDueDate,
            amount,
          }),
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  deleteInvoice = async (payeeCode: string, number: string) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}`,
        { method: "DELETE" }
      );
      if (response?.ok) {
        this.refresh();
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  setPQVNotRefundedStatus = async (
    payeeCode: string,
    number: string,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/PQV/not-refunded`,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  setPQVRestoreStatus = async (
    payeeCode: string,
    number: string,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/PQV/restore`,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  setPPVNotRefundedStatus = async (
    payeeCode: string,
    number: string,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/PPV/not-refunded`,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  setPPVRestoreStatus = async (
    payeeCode: string,
    number: string,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/PPV/restore`,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  setCreditMemoStatusExpected = async (
    payeeCode: string,
    number: string,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/credit-memo/expected`,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  setCreditMemoStatusNotPresent = async (
    payeeCode: string,
    number: string,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/credit-memo/not-present`,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  addCreditMemo = async (
    payeeCode: string,
    number: string,
    creditMemo: CreditMemo,
    single: boolean
  ) => {
    try {
      const response = await authService.authFetch(
        `/invoices/${payeeCode}/${number}/credit-memo/add`,
        {
          method: "POST",
          body: JSON.stringify(creditMemo),
        }
      );
      if (response?.ok) {
        if (single) {
          this.getInvoice(payeeCode, this.parentNumber(number));
        } else {
          this.refresh();
        }
      }
      return response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  parentNumber = (number: string): string => {
    return number.replace("SCR", "").replace("SC", "");
  };
}
