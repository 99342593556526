import { Col, Divider, Row, Table, TableColumnsType, Typography } from "antd";
import { useObservable } from "@/utils/use-observable";
import {
  renderBoolean,
  renderCurrency,
  renderDate,
  renderDateTime,
  renderStatus,
} from "@/utils";
import "./index.less";
import {
  Case,
  ShortageClaim,
  CreditMemo,
  Dispute,
  Invoice,
  InvoiceItem,
  PriceClaimItem,
  PriceClaim,
} from "@/models/invoice";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "@/app/components/loader";
import { authService, invoiceService } from "@/services";
import {
  renderSinglePPVStatus,
  renderSinglePQVStatus,
  renderSingleCreditMemoStatus,
  CreditMemoActionButton,
  renderSingleVCStatus,
} from "..";
import { CreditMemoStatus } from "@/models/enum/credit-memo-status";
import { Permission } from "@/models/enum/permission";
import { varianceStatusList } from "@/models/enum/variance-status";

const { Title, Text } = Typography;

export default function InvoicePage() {
  const manageInvoiceStatus = useObservable(
    authService.hasPermission(Permission.ManageInvoiceStatus)
  );
  const manageCreditMemoStatus = useObservable(
    authService.hasPermission(Permission.ManageCreditMemoStatus)
  );
  const invoice = useObservable(invoiceService.invoice);
  const payeeCode = useParams().payeeCode!!;
  const number = useParams().number!!;

  useEffect(() => {
    invoiceService.getInvoice(payeeCode, number);

    return () => {
      invoiceService.disposeInvoice();
    };
  }, [payeeCode, number]);

  if (!invoice) {
    return <Loader />;
  }

  return (
    <div className="invoice-page">
      <Title level={3}>Invoice {invoice.number}</Title>
      <Title level={5}>{invoice.payeeCode} - {invoice.accountName}</Title>
      <Divider />
      <Row className="invoice-details">
        <Col flex={1}>
          <Row>
            <Col className="label-column">
              <Text strong>Invoice Date:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderDate(invoice.date)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>V.C. Status:</Text>
            </Col>
            <Col className="value-column">
              <Text>
                {renderSingleVCStatus(
                  invoice.vendorCentralStatus,
                  invoice,
                  manageInvoiceStatus
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Status:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderStatus(varianceStatusList)(invoice.status)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Due Date:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderDate(invoice.paymentDueDate)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Approved On:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderDate(invoice.approvedOn)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Paid On:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderDate(invoice.paidOn)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Delivery Date:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderDate(invoice.deliveryDate)}</Text>
            </Col>
          </Row>
        </Col>
        <Col flex={1}>
          <Row>
            <Col className="label-column">
              <Text strong>Destination:</Text>
            </Col>
            <Col className="value-column">
              <Text>{invoice.destination}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Amount:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderCurrency(invoice.amount)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Initial Paid Amount:</Text>
            </Col>
            <Col className="value-column">
              <Text>{renderCurrency(invoice.actualPaidAmount)}</Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Remittance:</Text>
            </Col>
            <Col className="value-column">
              <Link to={"/remittances/" + invoice.remittanceNumber}>
                {invoice.remittanceNumber}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>PQV Status:</Text>
            </Col>
            <Col className="value-column">
              <Text>
                {renderSinglePQVStatus(
                  invoice.purchaseQuantityVarianceStatus,
                  invoice,
                  manageInvoiceStatus
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>PPV Status:</Text>
            </Col>
            <Col className="value-column">
              <Text>
                {renderSinglePPVStatus(
                  invoice.purchasePriceVarianceStatus,
                  invoice,
                  manageInvoiceStatus
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="label-column">
              <Text strong>Credit Memo Status:</Text>
            </Col>
            <Col className="value-column">
              <Text>
                {renderSingleCreditMemoStatus(
                  invoice.creditMemoStatus,
                  invoice,
                  manageCreditMemoStatus
                )}
              </Text>
            </Col>
          </Row>
        </Col>
        {invoice.priceClaim && (
          <Col flex={1}>
            <Row>
              <Col className="label-column">
                <Row>
                  <Text strong>Price Disc. Status:</Text>
                </Row>
                <Row>
                  <Text strong>Price Disc. Amount:</Text>
                </Row>
                <Row>
                  <Text strong>PC Remittances:</Text>
                </Row>
                <Row>
                  <Text strong>PCR Remittances:</Text>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Text>{invoice.priceClaim.status}</Text>
                </Row>
                <Row>
                  <Text>{renderCurrency(invoice.priceClaim.amount)}</Text>
                </Row>
                <Row>
                  <Text>
                    {invoice.priceClaim?.remittanceNumbers?.map(
                      (value: string, index: number) => (
                        <div key={value}>
                          <Link to={"/remittances/" + value}>{value}</Link>
                          {index <
                            invoice.priceClaim!.remittanceNumbers!
                              .length -
                            1
                            ? ", "
                            : ""}
                        </div>
                      )
                    )}
                  </Text>
                </Row>
                <Row>
                  <Text>
                    {invoice.priceClaim?.remittanceReversalNumbers?.map(
                      (value: string, index: number) => (
                        <div key={value}>
                          <Link to={"/remittances/" + value}>{value}</Link>
                          {index <
                            invoice.priceClaim!.remittanceReversalNumbers!
                              .length -
                            1
                            ? ", "
                            : ""}
                        </div>
                      )
                    )}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Divider />
      <InvoiceDetails
        invoice={invoice}
        payeeCode={invoice.payeeCode}
        creditMemoCreateEnabled={
          invoice.creditMemoStatus === CreditMemoStatus.CREATED ||
          invoice.creditMemoStatus === CreditMemoStatus.MATCHED
        }
      />
    </div>
  );
}

function InvoiceDetails(props: {
  invoice: Invoice | ShortageClaim | PriceClaim;
  payeeCode: string;
  creditMemoCreateEnabled: boolean;
}) {
  const invoiceItemColumns: TableColumnsType<InvoiceItem> = [
    {
      title: "Purchase Order",
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      width: "150px",
    },
    { title: "Asin", dataIndex: "asin", key: "asin", width: "100px" },
    {
      title: "External ID",
      dataIndex: "externalId",
      key: "externalId",
      width: "100px",
    },
    { title: "Title", dataIndex: "title", key: "title", width: "250px" },
    { title: "Model", dataIndex: "model", key: "model", width: "100px" },
    {
      title: "Freight Term",
      dataIndex: "freightTerm",
      key: "freightTerm",
      width: "100px",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "100px",
      align: "right",
    },
    {
      title: "Cost Price",
      dataIndex: "costPrice",
      key: "costPrice",
      width: "100px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Shortage Quantity",
      dataIndex: "shortageQuantity",
      key: "shortageQuantity",
      width: "100px",
      align: "right",
    },
    {
      title: "Amount Shortage",
      dataIndex: "amountShortage",
      key: "amountShortage",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Last Received Date",
      dataIndex: "lastReceivedDate",
      key: "lastReceivedDate",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Received Asin",
      dataIndex: "receivedAsin",
      key: "receivedAsin",
      width: "100px",
    },
    {
      title: "Received Quantity",
      dataIndex: "receivedQuantity",
      key: "receivedQuantity",
      width: "100px",
      align: "right",
    },
    {
      title: "Unit Cost",
      dataIndex: "unitCost",
      key: "unitCost",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Amount Received",
      dataIndex: "amountReceived",
      key: "amountReceived",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
  ];

  const claimColumns: TableColumnsType<ShortageClaim | PriceClaim> = [
    {
      title: "Invoice Number",
      key: "number",
      dataIndex: "number",
      width: "150px",
    },
    {
      title: "Invoice Date",
      key: "date",
      dataIndex: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "V.C. Status",
      key: "vendorCentralStatus",
      dataIndex: "vendorCentralStatus",
      width: "100px",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "100px",
      render: renderStatus(varianceStatusList)
    },
    {
      title: "Due Date",
      key: "paymentDueDate",
      dataIndex: "paymentDueDate",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Approved On",
      key: "approvedOn",
      dataIndex: "approvedOn",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Paid On",
      key: "paidOn",
      dataIndex: "paidOn",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Initial Paid Amount",
      key: "actualPaidAmount",
      dataIndex: "actualPaidAmount",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Has Dispute",
      key: "hasDisputes",
      width: "100px",
      align: "center",
      render: (i: Invoice) =>
        renderBoolean(!!i.dispute),
    },
    {
      title: "Has Credit Memos",
      key: "hasCreditMemos",
      width: "100px",
      align: "center",
      render: (i: Invoice) =>
        renderBoolean(i.creditMemos ? i.creditMemos?.length > 0 : false),
    },
    {
      title: "Remittance",
      key: "remittanceNumbers",
      dataIndex: "remittanceNumbers",
      width: "100px",
      render: (values?: string[]) => {
        return values?.map((value: string, index: number) => (
          <div key={value}>
            <Link to={"/remittances/" + value}>{value}</Link>
            {index < values.length - 1 ? ", " : ""}
          </div>
        ));
      },
    },
    {
      title: "Remittance Reversal",
      key: "remittanceReversalNumbers",
      dataIndex: "remittanceReversalNumbers",
      width: "100px",
      render: (values?: string[]) => {
        return values?.map((value: string, index: number) => (
          <div key={value}>
            <Link to={"/remittances/" + value}>{value}</Link>
            {index < values.length - 1 ? ", " : ""}
          </div>
        ));
      },
    }
  ];

  const priceClaimItemColumns: TableColumnsType<PriceClaimItem> = [
    {
      title: "Purchase Order",
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      width: "150px",
    },
    { title: "Asin", dataIndex: "asin", key: "asin", width: "100px" },
    {
      title: "External ID",
      dataIndex: "externalId",
      key: "externalId",
      width: "100px",
    },
    { title: "Title", dataIndex: "title", key: "title", width: "250px" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "100px",
      align: "right",
    },
    {
      title: "Invoice Cost",
      dataIndex: "invoiceCost",
      key: "invoiceCost",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Initial Research Cost",
      dataIndex: "initialResearchCost",
      key: "initialResearchCost",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Resolution Decision",
      dataIndex: "resolutionDecision",
      key: "resolutionDecision",
      width: "150px",
    },
    {
      title: "Resolution Cost",
      dataIndex: "resolutionCost",
      key: "resolutionCost",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
  ];

  const disputeColumns: TableColumnsType<Dispute> = [
    {
      title: "Dispute ID",
      dataIndex: "disputeId",
      key: "disputeId",
      width: "100px",
    },
    { title: "Status", dataIndex: "status", key: "status", width: "200px" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Approved Amount",
      dataIndex: "approvedAmount",
      key: "approvedAmount",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Has Reminders",
      key: "reminders",
      width: "100px",
      align: "center",
      render: (i: Dispute) =>
        renderBoolean((i.reminders?.length ?? 0) > 0),
    },
  ];

  const caseColumns: TableColumnsType<Case> = [
    {
      title: "Case ID",
      dataIndex: "caseId",
      key: "caseId",
      width: "100px",
    },
    { title: "Status", dataIndex: "status", key: "status", width: "250px" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Has Reminders",
      key: "reminders",
      width: "100px",
      align: "center",
      render: (i: Case) =>
        renderBoolean((i.reminders?.length ?? 0) > 0),
    },
  ];

  const creditMemoColumns: TableColumnsType<CreditMemo> = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "100px",
    },
    { title: "Status", dataIndex: "status", key: "status", width: "250px" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "130px",
      align: "right",
      render: renderCurrency,
    },
    {
      title: "Remittances",
      key: "remittanceNumbers",
      dataIndex: "remittanceNumbers",
      width: "100px",
      render: (values?: string[]) => {
        return values?.map((value: string, index: number) => (
          <div key={value}>
            <Link to={"/remittances/" + value}>{value}</Link>
            {index < values.length - 1 ? ", " : ""}
          </div>
        ));
      },
    },
  ];

  const reminderExpandedRowRender = (record: Dispute | Case) => {
    return (
      <>
        {(record.reminders?.length ?? 0) > 0 && (
          <>
            <Title level={4}>Reminders</Title>
            <div>
              {record.reminders
                ?.map((value: string) => renderDateTime(value))
                .join(", ")}
            </div>
          </>
        )}
      </>
    );
  };

  const expandedRowRender = (record: ShortageClaim | PriceClaim) => {
    return (
      <div className="expanded-row-block">
        <InvoiceDetails
          invoice={record}
          payeeCode={props.payeeCode}
          creditMemoCreateEnabled={props.creditMemoCreateEnabled}
        />
      </div>
    );
  };

  if (props.creditMemoCreateEnabled) {
    claimColumns.push({
      title: "Actions",
      key: "actions",
      width: "50px",
      render: (invoice: ShortageClaim) => {
        return (
          <CreditMemoActionButton
            value={CreditMemoStatus.CREATED}
            status={undefined}
            invoice={invoice}
            payeeCode={props.payeeCode}
            single={true}
          />
        );
      },
      align: "center",
    });
  }

  return (
    <>
      {(props.invoice instanceof Invoice || props.invoice instanceof ShortageClaim) &&
        <>
          <Title level={4}>Items</Title>
          <Table
            columns={invoiceItemColumns}
            dataSource={props.invoice.items}
            pagination={
              (props.invoice.items?.length ?? 0) > 10
                ? undefined
                : false
            }
            rowKey={(row: InvoiceItem) =>
              row.purchaseOrder + row.asin + row.externalId
            }
            size="small"
            scroll={{ x: 1920 }}
          />
        </>
      }
      {props.invoice instanceof PriceClaim && <>
        <Title level={4}>Items</Title>
        <Table
          columns={priceClaimItemColumns}
          dataSource={props.invoice.items}
          pagination={false}
          rowKey={(row: PriceClaimItem) =>
            row.purchaseOrder + row.asin + row.externalId
          }
          size="small"
          scroll={{ x: 1235 }}
        />
      </>}
      {props.invoice instanceof Invoice && (props.invoice.shortageClaims.length > 0 || props.invoice.priceClaims.length > 0) && (
        <>
          <Divider />
          <Title level={4}>Claims</Title>
          <Table
            columns={claimColumns}
            dataSource={props.invoice.claims}
            expandable={{ expandedRowRender }}
            pagination={false}
            rowKey="number"
            size="small"
            scroll={{ x: 1465 }}
          />
        </>
      )}
      {props.invoice.dispute && (
        <>
          <Divider />
          <Title level={4}>Dispute</Title>
          <Table
            columns={disputeColumns}
            dataSource={[props.invoice.dispute]}
            expandable={{
              expandedRowRender: reminderExpandedRowRender,
              rowExpandable: (dispute: Dispute) =>
                !!dispute.reminders && dispute.reminders.length > 0,
              showExpandColumn: (props.invoice.dispute.reminders?.length ?? 0) > 0
            }}
            pagination={false}
            rowKey="disputeId"
            size="small"
            scroll={{ x: 955 }}
          />
        </>
      )}
      {props.invoice.dispute?.cases && props.invoice.dispute.cases.length > 0 && (
        <>
          <Divider />
          <Title level={4}>Cases</Title>
          <Table
            columns={caseColumns}
            dataSource={props.invoice.dispute.cases}
            pagination={false}
            expandable={{
              expandedRowRender: reminderExpandedRowRender,
              rowExpandable: (cas: Case) => (cas.reminders?.length ?? 0) > 0,
              showExpandColumn: props.invoice.dispute.cases.every((cas: Case) => (cas.reminders?.length ?? 0) > 0)
            }}
            rowKey="caseId"
            size="small"
          />
        </>
      )}
      {(props.invoice.creditMemos?.length ?? 0) > 0 && (
        <>
          <Divider />
          <Title level={4}>Credit Memo</Title>
          <Table
            columns={creditMemoColumns}
            dataSource={props.invoice.creditMemos}
            pagination={false}
            rowKey="number"
            size="small"
            scroll={{ x: 580 }}
          />
        </>
      )}
    </>
  );
}
