import { Alert, Button, Checkbox, Form, Input, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { authService } from "@/services";
import logo from "@/logo.svg";
import "./index.less";

export default function LoginPage() {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    setLoading(true);
    authService
      .login(values.email, values.password, values.remember)
      .then((response) => {
        setLoading(false);
        if (response === false) {
          setError(true);
        }
      });
  };

  return (
    <div className="login-page">
      <div>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <Typography.Title level={1} className="title">{t("login.title")}</Typography.Title>
        <Typography.Title level={5} className="subtitle">{t("login.credit")}</Typography.Title>
        <div className="fixed-width-page">
          <div className="content">
            {error && (
              <Alert
                message={t("login.error.wrongEmailPassword")}
                type="error"
                className="alert"
              />
            )}
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label={t("login.email")} name="email">
                <Input />
              </Form.Item>
              <Form.Item label={t("login.password")} name="password">
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                initialValue="true"
              >
                <Checkbox>{t("login.rememberMe")}</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  {t("login.submit")}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Text><Link to="/reset-password">{t("login.forgotPassword")}</Link></Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
}
