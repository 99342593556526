// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboards-page {
  padding: 20px;
  height: 100%;
}
.dashboards-page .card {
  background-color: white;
  padding: 30px;
}
.dashboards-page .title {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;AAHA;EAKI,uBAAA;EACA,aAAA;AACJ;AAPA;EAUI,SAAA;AAAJ","sourcesContent":[".dashboards-page {\n  padding: 20px;\n  height : 100%;\n\n  .card {\n    background-color: white;\n    padding: 30px;\n  }\n\n  .title {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
