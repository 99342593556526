import { BehaviorSubject } from "rxjs";
import { authService } from ".";
import { Remittance } from "@/models/remittance";
import { Dayjs } from "dayjs";
import { apiDateFormat, downloadFileFromApiResponse } from "@/utils";
import { RemittanceFilters } from "@/filters/remittance";

export class RemittanceService {
  remittances = new BehaviorSubject<Remittance[]>([]);
  filters = new BehaviorSubject<RemittanceFilters>(new RemittanceFilters());
  isFetching = new BehaviorSubject(false);
  pageLoaded = [0];
  page = 0;
  limit = 100;
  remittance = new BehaviorSubject<Remittance | null>(null);
  isFetchingOne = new BehaviorSubject(false);

  dispose = async () => {
    this.remittances.next([]);
    this.filters.next(new RemittanceFilters());
    this.page = 0;
  };

  disposeRemittance = async () => {
    this.remittance.next(null);
  };

  getRemittances = async () => {
    const remittances = await this._getRemittances(0, this.limit);
    if (remittances) {
      this.pageLoaded = [0];
      this.remittances.next(remittances);
    }
  };

  refresh = async () => {
    const remittances = await this._getRemittances(
      0,
      (this.page + 1) * this.limit,
      false
    );
    if (remittances) {
      this.remittances.next(remittances);
    }
  };

  exportRemittances = async () => {
    try {
      const response = await authService.authFetch(
        `/remittances/export`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );

      if (response?.ok) {
        await downloadFileFromApiResponse(response);
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  next = async () => {
    if (!this.pageLoaded.includes(this.page + 1)) {
      this.pageLoaded.push(this.page + 1);
      const remittances = await this._getRemittances(this.page + 1, this.limit);
      if (!remittances) {
        this.pageLoaded.pop();
      } else if (remittances.length > 0) {
        this.remittances.next([...this.remittances.value, ...remittances]);
      }
    }
  };

  _getRemittances = async (
    page: number,
    limit: number,
    savePage: boolean = true
  ) => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        `/remittances?page=${page}&limit=${limit}`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );
      if (response?.ok) {
        const remittances = ((await response.json()) as Remittance[]).map(
          (remittance) => Object.assign(new Remittance(), remittance)
        );
        if (savePage && remittances.length > 0) {
          this.page = page;
        }
        return remittances;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
    return null;
  };

  setNumbers = async (value: string[]) => {
    const filters = this.filters.value;
    filters.numbers = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setDateFrom = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateFrom = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setDateTo = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateTo = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setAccountNames = async (value: string[]) => {
    const filters = this.filters.value;
    filters.accountNames = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setStatuses = async (value: string[]) => {
    const filters = this.filters.value;
    filters.statuses = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setInvoiceNumbers = async (value: string[]) => {
    const filters = this.filters.value;
    filters.invoiceNumbers = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  getRemittance = async (number: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch(`/remittances/${number}`, {
        method: "GET",
      });
      if (response?.ok) {
        this.remittance.next(
          Object.assign(new Remittance(), await response.json())
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };
}
