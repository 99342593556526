import { DisputeInvoiceAction } from "@/models/enum/dispute-invoice-action";
import { DisputeInvoiceStatus } from "@/models/enum/dispute-invoice-status";

export class DisputeInvoiceLogFilters {
  dateFrom?: string;
  dateTo?: string;
  accountNames?: string[];
  actions?: DisputeInvoiceAction[];
  statuses?: DisputeInvoiceStatus[];
  invoiceNumbers?: string[];
  disputeIds?: string[];
  caseIds?: string[];
}
