// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendor-central-credentials-page {
  padding: 20px;
}
.vendor-central-credentials-page .actions {
  margin-bottom: 20px;
}
@media (min-width: 767px) {
  .vendor-central-credentials-page .actions .action {
    flex: 0;
    min-width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/main/vendor-central-credentials/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAGI,mBAAA;AAEJ;AADI;EAAA;IAEI,OAAA;IACA,gBAAA;EAGN;AACF","sourcesContent":[".vendor-central-credentials-page {\n  padding: 20px;\n  .actions {\n    margin-bottom: 20px;\n    @media (min-width: 767px) {\n      .action {\n        flex: 0;\n        min-width: 200px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
