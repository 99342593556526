export enum RemittanceType {
  INVOICE = "INVOICE",
  SC = "SC",
  SCR = "SCR",
  PC = "PC",
  PCR = "PCR",
  CM = "CM",
  CMR = "CMR",
  UNKNOWN = "UNKNOWN"
}

export const remittanceTypeList = [
  { value: RemittanceType.INVOICE, label: "Invoice" },
  { value: RemittanceType.SC, label: "Shortage Claim" },
  { value: RemittanceType.SCR, label: "Shortage Claim Reversal" },
  { value: RemittanceType.PC, label: "Price Claim" },
  { value: RemittanceType.PCR, label: "Price Claim Reversal" },
  { value: RemittanceType.CM, label: "Credit Memo" },
  { value: RemittanceType.CMR, label: "Credit Memo Reversal" },
  { value: RemittanceType.UNKNOWN, label: "Unknown" },
];
