export enum VarianceStatus {
  NOT_PRESENT = "NOT_PRESENT",
  DISPUTE_TO_DO = "DISPUTE_TO_DO",
  DISPUTE_PENDING = "DISPUTE_PENDING",
  AUTOMATICALLY_REFUNDED = "AUTOMATICALLY_REFUNDED",
  REFUNDED_WITH_DISPUTE = "REFUNDED_WITH_DISPUTE",
  NOT_REFUNDED = "NOT_REFUNDED",
}

export const varianceStatusList = [
  { value: VarianceStatus.NOT_PRESENT, label: "Not Present" },
  { value: VarianceStatus.DISPUTE_TO_DO, label: "Dispute to do", color: "red" },
  {
    value: VarianceStatus.DISPUTE_PENDING,
    label: "Dispute pending",
    color: "red",
  },
  {
    value: VarianceStatus.AUTOMATICALLY_REFUNDED,
    label: "Automatically Refunded",
    color: "green",
  },
  {
    value: VarianceStatus.REFUNDED_WITH_DISPUTE,
    label: "Refunded with dispute",
    color: "green",
  },
  { value: VarianceStatus.NOT_REFUNDED, label: "Not Refunded", color: "red" },
];
