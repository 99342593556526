import { BehaviorSubject } from "rxjs";
import { AdminRole } from "@/models/role";
import { authService } from ".";

export class AdminRoleService {
  roles = new BehaviorSubject<AdminRole[]>([]);
  isFetching = new BehaviorSubject<boolean>(false);
  role = new BehaviorSubject<AdminRole | null>(null);
  isFetchingOne = new BehaviorSubject<boolean>(false);
  isAdding = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);
  isDeleting = new BehaviorSubject<boolean>(false);

  dispose = async () => {
    this.roles.next([]);
  };

  disposeRole = async () => {
    this.role.next(null);
  };

  getRoles = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch("/admin/roles", {
        method: "GET",
      });
      if (response?.ok) {
        this.roles.next(
          ((await response.json()) as AdminRole[]).map((x) =>
            Object.assign(new AdminRole(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getRole = async (id: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch("/admin/roles/" + id, {
        method: "GET",
      });
      if (!!response?.ok) {
        this.role.next(Object.assign(new AdminRole(), await response.json()));
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  addRole = async (
    name: string,
    permissions: string[],
    organizationId: string
  ) => {
    try {
      this.isAdding.next(true);
      const response = await authService.authFetch("/admin/roles", {
        method: "POST",
        body: JSON.stringify({ name, permissions, organizationId }),
      });
      if (!!response?.ok) {
        this.refresh();
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isAdding.next(false);
    }
    return false;
  };

  updateRole = async (id: string, name: string, permissions: string[]) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch("/admin/roles", {
        method: "PATCH",
        body: JSON.stringify({ id, name, permissions }),
      });

      if (!!response?.ok) {
        this.role.next(Object.assign(this.role.value!!, { name }));
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };

  deleteRole = async (id: string) => {
    try {
      this.isDeleting.next(true);
      const response = await authService.authFetch("/admin/roles/" + id, {
        method: "DELETE",
      });
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isDeleting.next(false);
    }
    return false;
  };
}
