import { CaseStatus } from "@/models/enum/case-status";
import { CreditMemoStatus } from "@/models/enum/credit-memo-status";
import { InvoiceStatus } from "@/models/enum/invoice-status";
import { VarianceStatus } from "@/models/enum/variance-status";

export class InvoiceFilters {
  numbers?: string[];
  dateFrom?: string;
  dateTo?: string;
  accountNames?: string[];
  vendorCentralStatuses?: InvoiceStatus[];
  payeeCodes?: string[];
  paymentDueDateFrom?: string;
  paymentDueDateTo?: string;
  hasClaims?: boolean;
  disputeIds?: string[];
  caseIds?: string[];
  caseStatuses?: CaseStatus[];
  creditMemoNumbers?: string[];
  creditMemoStatuses?: CreditMemoStatus[];
  purchaseQuantityVarianceStatuses?: VarianceStatus[];
  purchasePriceVarianceStatuses?: VarianceStatus[];
  eans?: string[];
  asins?: string[];
  purchaseOrders?: string[];
}
