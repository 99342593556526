import { CloudDownloadOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Select,
  Row,
  Space,
  Table,
  Typography,
  Tooltip,
  Dropdown,
  MenuProps,
  Modal,
  Input,
  Form,
  InputNumber,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  authService,
  invoiceService,
  vendorCentralCredentialService,
} from "@/services";
import { useObservable } from "@/utils/use-observable";
import dayjs, { Dayjs } from "dayjs";
import {
  apiDateFormat,
  apiDateTimeFormat,
  dateFormat,
  renderBoolean,
  renderCurrency,
  renderDate,
  renderStatus,
  tokenSeparator,
  trueFalseList,
} from "@/utils";
import "./index.less";
import { TableRef } from "antd/es/table";
import { CreditMemo, Invoice, ShortageClaim } from "@/models/invoice";
import { ColumnsType } from "antd/lib/table";
import { invoiceStatusList } from "@/models/enum/invoice-status";
import { Link } from "react-router-dom";
import {
  VarianceStatus,
  varianceStatusList,
} from "@/models/enum/variance-status";
import {
  CreditMemoStatus,
  creditMemoStatusList,
} from "@/models/enum/credit-memo-status";
import {
  StopOutlined,
  FileSyncOutlined,
  EllipsisOutlined,
  FileExcelOutlined,
  FileAddOutlined,
  IssuesCloseOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Permission } from "@/models/enum/permission";
import { useTranslation } from "react-i18next";
import { caseStatusList } from "@/models/enum/case-status";

const { Title } = Typography;

export default function InvoicesPage() {
  const manageInvoiceStatus = useObservable(
    authService.hasPermission(Permission.ManageInvoiceStatus)
  );
  const manageCreditMemoStatus = useObservable(
    authService.hasPermission(Permission.ManageCreditMemoStatus)
  );

  const items: CollapseProps["items"] = [
    {
      key: "filters",
      label: "Filters",
      children: <FilterForm />,
    },
  ];

  const columns: ColumnsType<Invoice> = [
    {
      title: "Invoice Number",
      key: "number",
      dataIndex: "number",
      width: "130px",
      render: (value: string, record: Invoice) => {
        return <Link to={`${record.payeeCode}/${record.number}`}>{value}</Link>;
      },
    },
    {
      title: "Invoice Date",
      key: "date",
      dataIndex: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Account Name",
      key: "accountName",
      dataIndex: "accountName",
      width: "250px",
    },
    {
      title: "V.C. Status",
      key: "vendorCentralStatus",
      dataIndex: "vendorCentralStatus",
      width: "150px",
      render: renderVCStatus(manageInvoiceStatus),
    },
    {
      title: "Due Date",
      key: "paymentDueDate",
      dataIndex: "paymentDueDate",
      width: "150px",
      render: renderDate,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      align: "right",
      width: "100px",
      render: renderCurrency,
    },
    {
      title: "Initial Paid Amount",
      key: "actualPaidAmount",
      dataIndex: "actualPaidAmount",
      align: "right",
      width: "150px",
      render: renderCurrency,
    },
    {
      title: "Remaining Amount",
      key: "remainingAmount",
      dataIndex: "remainingAmount",
      align: "right",
      width: "150px",
      render: renderCurrency,
    },
    {
      title: "Remittance",
      key: "remittanceNumber",
      dataIndex: "remittanceNumber",
      width: "100px",
      render: (value: string) => (
        <Link to={"/remittances/" + value}>{value}</Link>
      ),
    },
    {
      title: "Has claims",
      key: "hasClaims",
      align: "center",
      width: "100px",
      render: (i: Invoice) =>
        renderBoolean(i.shortageClaims ? i.shortageClaims?.length > 0 : false),
    },
    {
      title: "PQV Status",
      key: "purchaseQuantityVarianceStatus",
      dataIndex: "purchaseQuantityVarianceStatus",
      width: "150px",
      render: renderPQVStatus(manageInvoiceStatus),
    },
    {
      title: "PPV Status",
      key: "purchasePriceVarianceStatus",
      dataIndex: "purchasePriceVarianceStatus",
      width: "150px",
      render: renderPPVStatus(manageInvoiceStatus),
    },
    {
      title: "Credit Memo Status",
      key: "creditMemoStatus",
      dataIndex: "creditMemoStatus",
      width: "150px",
      render: renderCreditMemoStatus(manageCreditMemoStatus),
    },
  ];

  const isFetching = useObservable(invoiceService.isFetching);
  const invoices = useObservable(invoiceService.invoices);
  var tableRef = useRef<TableRef>(null);

  const onScroll = (event: any) => {
    if (event.target) {
      let maxScroll = Math.round(
        event.target.scrollHeight - event.target.clientHeight
      );
      let currentScroll = Math.round(event.target.scrollTop);
      if (currentScroll >= maxScroll - 100) {
        invoiceService.next();
      }
    }
  };

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const tbody =
        tableRef.current.nativeElement.querySelector(".ant-table-body");
      if (tbody) {
        tbody.addEventListener("scroll", onScroll);

        return () => {
          tbody.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, []);

  useEffect(() => {
    const subscribe = invoiceService.filters.subscribe(() => {
      tableRef.current?.nativeElement
        .querySelector(".ant-table-body")
        ?.scrollTo({ top: 0 });
      invoiceService.getInvoices();
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const onRefresh = () => {
    invoiceService.refresh();
  };

  const onExportCsv = () => {
    invoiceService.exportInvoices();
  };

  return (
    <div className="invoices-page">
      <Title level={3}>Invoices</Title>
      <Row className="actions" justify={"space-between"}>
        <Space>
          <Button icon={<SyncOutlined spin={isFetching} />} onClick={onRefresh}>
            Refresh
          </Button>
          <Button icon={<CloudDownloadOutlined />} onClick={onExportCsv}>
            Export
          </Button>
        </Space>
      </Row>
      <Collapse
        className="filters"
        size="small"
        bordered={false}
        items={items}
      />
      <Table
        ref={tableRef}
        columns={columns}
        dataSource={invoices}
        rowKey="id"
        size="small"
        pagination={false}
        scroll={{ y: "calc(100vh - 233px)" }}
      />
    </div>
  );
}

function FilterForm() {
  const filters = useObservable(invoiceService.filters);
  const allAccounts = useObservable(vendorCentralCredentialService.allAccounts);

  useEffect(() => {
    vendorCentralCredentialService.getVendorCentralCredentials();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.numbers}
          onChange={invoiceService.setNumbers}
          placeholder="Invoice Numbers"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.dateFrom ? dayjs(filters.dateFrom, apiDateTimeFormat) : null
          }
          onChange={invoiceService.setDateFrom}
          placeholder="Invoice Date from"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.dateTo ? dayjs(filters.dateTo, apiDateTimeFormat) : null
          }
          onChange={invoiceService.setDateTo}
          placeholder="Invoice Date to"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={allAccounts}
          optionFilterProp="label"
          value={filters.accountNames}
          onChange={invoiceService.setAccountNames}
          placeholder="Account Names"
          allowClear
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={invoiceStatusList}
          value={filters.vendorCentralStatuses}
          mode="multiple"
          onChange={invoiceService.setVCStatuses}
          allowClear
          placeholder="V.C. Status"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.payeeCodes}
          onChange={invoiceService.setPayees}
          placeholder="Payee Codes"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.paymentDueDateFrom
              ? dayjs(filters.paymentDueDateFrom, apiDateTimeFormat)
              : null
          }
          onChange={invoiceService.setPaymentDueDateFrom}
          placeholder="Due Date from"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.paymentDueDateTo
              ? dayjs(filters.paymentDueDateTo, apiDateTimeFormat)
              : null
          }
          onChange={invoiceService.setPaymentDueDateTo}
          placeholder="Due Date to"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={trueFalseList}
          value={filters.hasClaims}
          onChange={invoiceService.setHasClaims}
          allowClear
          placeholder="Has Claims"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.disputeIds}
          onChange={invoiceService.setDisputeIds}
          placeholder="Dispute IDs"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.caseIds}
          onChange={invoiceService.setCaseIds}
          placeholder="Case IDs"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={caseStatusList}
          value={filters.caseStatuses}
          mode="multiple"
          onChange={invoiceService.setCaseStatuses}
          allowClear
          placeholder="Case Statuses"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.creditMemoNumbers}
          onChange={invoiceService.setCreditMemoNumbers}
          placeholder="Credit Memo Numbers"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={varianceStatusList}
          value={filters.purchaseQuantityVarianceStatuses}
          mode="multiple"
          onChange={invoiceService.setPurchaseQuantityVarianceStatuses}
          allowClear
          placeholder="PQV Statuses"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={varianceStatusList}
          value={filters.purchasePriceVarianceStatuses}
          mode="multiple"
          onChange={invoiceService.setPurchasePriceVarianceStatuses}
          allowClear
          placeholder="PPV Statuses"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={creditMemoStatusList}
          value={filters.creditMemoStatuses}
          mode="multiple"
          onChange={invoiceService.setCreditMemoStatuses}
          allowClear
          placeholder="Credit Memo Statuses"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.eans}
          onChange={invoiceService.setEans}
          placeholder="EANs"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.asins}
          onChange={invoiceService.setAsins}
          placeholder="ASINs"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.purchaseOrders}
          onChange={invoiceService.setPurcahseOrders}
          placeholder="Purchase Orders"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
    </Row>
  );
}

export const renderVCStatus =
  (manageInvoiceStatus?: boolean) => (status: string, invoice: Invoice) =>
    renderSingleVCStatus(status, invoice, manageInvoiceStatus, false);

export const renderSingleVCStatus = (
  status: string,
  invoice: Invoice,
  manageInvoiceStatus?: boolean,
  single: boolean = true
) => {
  return (
    <SetClosedStatusButton
      status={status}
      invoice={invoice}
      single={single}
      manageInvoiceStatus={manageInvoiceStatus}
    />
  );
};

export const renderPQVStatus =
  (manageInvoicePQVStatus?: boolean) =>
    (value: string | undefined, invoice: Invoice) =>
      renderSinglePQVStatus(value, invoice, manageInvoicePQVStatus, false);

export const renderSinglePQVStatus = (
  value: string | undefined,
  invoice: Invoice,
  manageInvoicePQVStatus?: boolean,
  single: boolean = true
) => {
  const status = renderStatus(varianceStatusList)(value);

  if (manageInvoicePQVStatus) {
    if (
      value === VarianceStatus.DISPUTE_PENDING ||
      value === VarianceStatus.DISPUTE_TO_DO
    ) {
      return (
        <Space>
          {status}
          <Tooltip title='Set as "Not Refunded"'>
            <Button
              shape="circle"
              size="small"
              icon={<StopOutlined />}
              onClick={() =>
                invoiceService.setPQVNotRefundedStatus(
                  invoice.payeeCode,
                  invoice.number,
                  single
                )
              }
            ></Button>
          </Tooltip>
        </Space>
      );
    }

    if (value === VarianceStatus.NOT_REFUNDED) {
      return (
        <Space>
          {status}
          <Tooltip title="Restore status">
            <Button
              shape="circle"
              size="small"
              icon={<UndoOutlined />}
              onClick={() =>
                invoiceService.setPQVRestoreStatus(
                  invoice.payeeCode,
                  invoice.number,
                  single
                )
              }
            ></Button>
          </Tooltip>
        </Space>
      );
    }
  }

  return status;
};

export const renderPPVStatus =
  (manageInvoicePPVStatus?: boolean) =>
    (value: string | undefined, invoice: Invoice) =>
      renderSinglePPVStatus(value, invoice, manageInvoicePPVStatus, false);

export const renderSinglePPVStatus = (
  value: string | undefined,
  invoice: Invoice,
  manageInvoicePPVStatus?: boolean,
  single: boolean = true
) => {
  const status = renderStatus(varianceStatusList)(value);

  if (manageInvoicePPVStatus) {
    if (
      value === VarianceStatus.DISPUTE_PENDING ||
      value === VarianceStatus.DISPUTE_TO_DO
    ) {
      return (
        <Space>
          {status}
          <Tooltip title='Set as "Not Refunded"'>
            <Button
              shape="circle"
              size="small"
              icon={<StopOutlined />}
              onClick={() =>
                invoiceService.setPPVNotRefundedStatus(
                  invoice.payeeCode,
                  invoice.number,
                  single
                )
              }
            ></Button>
          </Tooltip>
        </Space>
      );
    }

    if (value === VarianceStatus.NOT_REFUNDED) {
      return (
        <Space>
          {status}
          <Tooltip title="Restore status">
            <Button
              shape="circle"
              size="small"
              icon={<UndoOutlined />}
              onClick={() =>
                invoiceService.setPPVRestoreStatus(
                  invoice.payeeCode,
                  invoice.number,
                  single
                )
              }
            ></Button>
          </Tooltip>
        </Space>
      );
    }
  }

  return status;
};

export const renderCreditMemoStatus =
  (manageCreditMemoStatus?: boolean) =>
    (value: string | undefined, invoice: Invoice) =>
      renderSingleCreditMemoStatus(value, invoice, manageCreditMemoStatus, false);

export const renderSingleCreditMemoStatus = (
  value: string | undefined,
  invoice: Invoice,
  manageCreditMemoStatus?: boolean,
  single: boolean = true
) => {
  const status = renderStatus(creditMemoStatusList)(value);

  if (manageCreditMemoStatus && value === CreditMemoStatus.NOT_PRESENT) {
    return (
      <Space>
        {status}
        <Tooltip title="Expect a credit memo">
          <Button
            shape="circle"
            size="small"
            icon={<FileSyncOutlined />}
            onClick={() =>
              invoiceService.setCreditMemoStatusExpected(
                invoice.payeeCode,
                invoice.number,
                single
              )
            }
          ></Button>
        </Tooltip>
      </Space>
    );
  }

  if (
    manageCreditMemoStatus &&
    (value === CreditMemoStatus.EXPECTED ||
      value === CreditMemoStatus.CREATED ||
      value === CreditMemoStatus.MATCHED)
  ) {
    return (
      <CreditMemoActionButton
        value={value}
        status={status}
        invoice={invoice}
        payeeCode={invoice.payeeCode}
        single={single}
        manageCreditMemoStatus={manageCreditMemoStatus}
      />
    );
  }

  return status;
};

export function CreditMemoActionButton(props: {
  value: CreditMemoStatus;
  status: string | undefined;
  invoice: Invoice | ShortageClaim;
  payeeCode: string;
  single: boolean;
  manageCreditMemoStatus?: boolean;
}) {
  const [visible, setVisible] = useState(false);

  const items: MenuProps["items"] = [];

  if (props.manageCreditMemoStatus) {
    items.push(
      {
        key: "1",
        label: "Don't expect a Credit Memo",
        icon: <FileExcelOutlined />,
        onClick: () =>
          invoiceService.setCreditMemoStatusNotPresent(
            props.payeeCode,
            props.invoice.number,
            props.single
          ),
      },
      {
        key: "2",
        label: "Add new Credit Memo",
        icon: <FileAddOutlined />,
        onClick: () => setVisible(true),
      }
    );
  }

  const hideModal = () => {
    setVisible(false);
  };

  const onFinish = (creditMemo?: CreditMemo) => {
    hideModal();

    if (creditMemo) {
      invoiceService.addCreditMemo(
        props.payeeCode,
        props.invoice.number,
        creditMemo,
        props.single
      );
    }
  };

  let button;

  if (props.value === CreditMemoStatus.EXPECTED) {
    button = (
      <Dropdown menu={{ items }}>
        <Button
          shape="circle"
          size="small"
          icon={<EllipsisOutlined />}
        ></Button>
      </Dropdown>
    );
  }

  if (
    props.value === CreditMemoStatus.CREATED ||
    props.value === CreditMemoStatus.MATCHED
  ) {
    button = (
      <Tooltip title="Add new Credit Memo">
        <Button
          shape="circle"
          size="small"
          icon={<FileAddOutlined />}
          onClick={() => setVisible(true)}
        ></Button>
      </Tooltip>
    );
  }

  return (
    <Space>
      {props.status}
      {button}
      {visible && (
        <Modal
          open={true}
          onCancel={hideModal}
          title="Add Credit Memo"
          footer={null}
          width={300}
        >
          <AddCreditMemo onFinish={onFinish} />
        </Modal>
      )}
    </Space>
  );
}

export function SetClosedStatusButton(props: {
  status: string | undefined;
  invoice: Invoice;
  single: boolean;
  manageInvoiceStatus?: boolean;
}) {
  const [visible, setVisible] = useState(false);

  const hideModal = () => {
    setVisible(false);
  };

  const onFinish = (paymentDueDate: string, amount: number, date?: string) => {
    hideModal();

    invoiceService.setClosedStatus(
      props.invoice.payeeCode,
      props.invoice.number,
      date,
      paymentDueDate,
      amount,
      props.single
    );
  };

  const onDelete = () => {
    invoiceService.deleteInvoice(props.invoice.payeeCode, props.invoice.number).then((response) => {
      if (response) {
        message.success("Invoice " + props.invoice.number + " deleted", 4);
      }
    });
  };

  if (props.manageInvoiceStatus && props.status === "Not found") {
    return (
      <Space>
        {props.status}
        <Tooltip title="Add information to close invoice">
          <Button
            shape="circle"
            size="small"
            icon={<IssuesCloseOutlined />}
            onClick={() => setVisible(true)}
          ></Button>
          {visible && (
            <Modal
              open={true}
              onCancel={hideModal}
              title="Add information to close invoice"
              footer={null}
              width={300}
            >
              <SetClosedStatus onClose={hideModal} onFinish={onFinish} />
            </Modal>
          )}
        </Tooltip>
        <Tooltip title="Delete invoice">
          <Button
            shape="circle"
            size="small"
            icon={<DeleteOutlined />}
            onClick={onDelete}
          ></Button>
        </Tooltip>
      </Space>
    );
  }

  return <>{props.status}</>;
}

function AddCreditMemo(props: { onFinish: (creditMemo?: CreditMemo) => void }) {
  const [form] = Form.useForm();
  const [t] = useTranslation();

  const onSubmit = ({
    date,
    number,
    amount,
  }: {
    date: Dayjs;
    number: string;
    amount: number;
  }) => {
    props.onFinish(
      Object.assign(new CreditMemo(), {
        date: date.format(apiDateFormat),
        number,
        amount,
      })
    );
  };

  const onClose = () => {
    props.onFinish(undefined);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        name="date"
        label="Date"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <DatePicker format={dateFormat()} style={{ width: "100%" }} allowClear />
      </Form.Item>
      <Form.Item
        name="number"
        label="Number"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<number>
        name="amount"
        label="Amount"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <InputNumber type="number" style={{ width: "100%" }} />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col flex={1}>
          <Button type="primary" block htmlType="submit">
            Save
          </Button>
        </Col>
        <Col flex={1}>
          <Button danger block onClick={onClose}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function SetClosedStatus(props: {
  onClose: () => void;
  onFinish: (paymentDueDate: string, amount: number, date?: string) => void;
}) {
  const [form] = Form.useForm();
  const [t] = useTranslation();

  const onSubmit = ({
    date,
    paymentDueDate,
    amount,
  }: {
    date: Dayjs;
    paymentDueDate: Dayjs;
    amount: number;
  }) => {
    props.onFinish(
      paymentDueDate.format(apiDateFormat),
      amount,
      date.format(apiDateFormat)
    );
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item name="date" label="Date">
        <DatePicker format={dateFormat()} style={{ width: "100%" }} allowClear />
      </Form.Item>
      <Form.Item
        name="paymentDueDate"
        label="Payment Due Date"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <DatePicker format={dateFormat()} style={{ width: "100%" }} allowClear />
      </Form.Item>
      <Form.Item<number>
        name="amount"
        label="Amount"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <InputNumber type="number" style={{ width: "100%" }} />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col flex={1}>
          <Button type="primary" block htmlType="submit">
            Save
          </Button>
        </Col>
        <Col flex={1}>
          <Button danger block onClick={props.onClose}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
