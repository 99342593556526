import { BehaviorSubject } from "rxjs";
import { authService } from "..";
import { DisputeInvoiceSettings } from "@/models/dispute-invoice-settings";

export class DisputeInvoiceSettingsService {
  isFetching = new BehaviorSubject(false);
  isUpdating = new BehaviorSubject(false);
  settings = new BehaviorSubject<DisputeInvoiceSettings | undefined>(undefined);

  dispose = async () => {
    this.isFetching.next(false);
    this.settings.next(undefined);
  };

  getSettings = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        "/dispute-invoices/settings",
        {
          method: "GET",
        }
      );
      if (!!response?.ok) {
        this.settings.next(
          Object.assign(new DisputeInvoiceSettings(), await response.json())
        );
      }
    } catch (e) {
    } finally {
      this.isFetching.next(false);
    }
  };

  updateSettings = async (settings: DisputeInvoiceSettings) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch(
        "/dispute-invoices/settings",
        {
          method: "POST",
          body: JSON.stringify(settings),
        }
      );
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };
}
