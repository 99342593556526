// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page {
  padding: 20px;
  height: 100%;
}
.dashboard-page .center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/dashboard/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;AAHA;EAKI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".dashboard-page {\n  padding: 20px;\n  height : 100%;\n\n  .center {\n    height         : 100%;\n    display        : flex;\n    justify-content: center;\n    align-items    : center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
