import {
  ApiOutlined,
  AreaChartOutlined,
  FileSearchOutlined,
  DeploymentUnitOutlined,
  SettingOutlined,
  UnlockOutlined,
  UserOutlined,
  FileDoneOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  EuroOutlined,
  CreditCardOutlined,
  TrademarkOutlined,
  DatabaseOutlined,
  MenuOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, Popover } from "antd";
import { useLocation, Outlet, Link } from "react-router-dom";
import { authService, dashboardService, menuService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import logo from "@/logo.svg";
import "./index.less";
import { Permission } from "@/models/enum/permission";
import { useEffect, useState } from "react";

const { Content, Sider } = Layout;

export default function MainPage() {
  const collapsed = useObservable(menuService.collapsed);

  useEffect(() => {
    dashboardService.getFavorites();
  }, []);

  return (
    <Layout className="layout">
      <Sider
        collapsible
        collapsed={collapsed}
        collapsedWidth={50}
        width={250}
        className="sider"
        onCollapse={menuService.setCollapsed}
      >
        <MainMenu burger={false} />
      </Sider>

      <BurgerMenu />

      <Content className={"content" + (collapsed ? " collapsed" : "")}>
        <Outlet />
      </Content>
    </Layout>
  );
}

function BurgerMenu() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div className="menu-button">
      <Popover
        content={<MainMenu burger={true} />}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button icon={<MenuOutlined />} />
      </Popover>
    </div>
  );
}

function MainMenu(props: { burger: boolean }) {
  const location = useLocation();
  const claims = useObservable(authService.claims);
  const admin = useObservable(authService.isAdmin);
  const manageDashboards = useObservable(
    authService.hasPermission(Permission.ManageDashboards)
  );
  const manageAmazonVendorCentralCredentials = useObservable(
    authService.hasPermission(Permission.ManageAmazonVendorCentralCredentials)
  );
  const manageIntegration = useObservable(
    authService.hasPermission(Permission.ManageIntegration)
  );
  const manageInvoices = useObservable(
    authService.hasPermission(Permission.ManageInvoices)
  );
  const manageRemittances = useObservable(
    authService.hasPermission(Permission.ManageRemittances)
  );
  const manageDisputeInvoiceSettings = useObservable(
    authService.hasPermission(Permission.ManageDisputeInvoiceSettings)
  );
  const manageDisputeInvoiceLogs = useObservable(
    authService.hasPermission(Permission.ManageDisputeInvoiceLogs)
  );
  const manageBilling = useObservable(
    authService.hasPermission(Permission.ManageBilling)
  );
  const favorites = useObservable(dashboardService.favorites);

  const getCurrentKey = () => {
    let path = location.pathname.substring(1);
    if (path.endsWith("/")) {
      path = path.substring(0, path.length - 1);
    }

    if (path.startsWith("dashboards")) {
      if (
        path === "dashboards" ||
        path === "dashboards/news" ||
        favorites.some((fav) => path.endsWith(fav.id))
      ) {
        return path;
      } else {
        return "dashboardsGroup";
      }
    }

    if (path.startsWith("invoices")) {
      return "invoices";
    }
    if (path.startsWith("remittances")) {
      return "remittances";
    }
    if (path.startsWith("settings")) {
      return "settings";
    }
    if (path.startsWith("admin/settings")) {
      return "admin/settings";
    }
    return path;
  };

  const topMenuItems: MenuProps["items"] = [
    {
      key: "dashboardsGroup",
      label: "Dashboards",
      icon: <AreaChartOutlined />,
      children: [
        {
          key: "dashboards",
          label: <Link to="/dashboards">Dashboard List</Link>,
          icon: <UnorderedListOutlined />,
        },
        ...(manageDashboards
          ? [
            {
              key: "dashboards/new",
              label: <Link to="/dashboards/new">Add New Dashboard</Link>,
              icon: <PlusOutlined />,
            },
          ]
          : []),
        {
          type: "divider",
        },
        ...favorites.map((dashboard) => ({
          key: "dashboards/" + dashboard.id,
          label: (
            <Link to={"/dashboards/" + dashboard.id}>{dashboard.name}</Link>
          ),
          icon: <StarFilled style={{ color: "gold" }} />,
        })),
      ],
    },
    ...(manageAmazonVendorCentralCredentials
      ? [
        {
          key: "vendor-central-credentials",
          label: (
            <Link to="/vendor-central-credentials">V. C. Credentials</Link>
          ),
          icon: <DeploymentUnitOutlined />,
        },
      ]
      : []),
    ...(manageIntegration
      ? [
        {
          key: "integration",
          label: <Link to="/integration">Integration</Link>,
          icon: <ApiOutlined />,
        },
      ]
      : []),
    ...(manageInvoices ||
      manageRemittances ||
      manageDisputeInvoiceSettings ||
      manageDisputeInvoiceLogs
      ? [
        {
          key: "dataGroup",
          label: "Data",
          icon: <DatabaseOutlined />,
          children: [
            ...(manageInvoices
              ? [
                {
                  key: "invoices",
                  label: <Link to="/invoices">Invoices</Link>,
                  icon: <FileDoneOutlined />,
                },
              ]
              : []),
            ...(manageRemittances
              ? [
                {
                  key: "remittances",
                  label: <Link to="/remittances">Remittances</Link>,
                  icon: <EuroOutlined />,
                },
              ]
              : []),
            ...(manageDisputeInvoiceSettings || manageDisputeInvoiceLogs
              ? [
                {
                  key: "dispute-invoices",
                  label: "Dispute invoices",
                  icon: <FileSearchOutlined />,
                  children: [
                    ...(manageDisputeInvoiceSettings
                      ? [
                        {
                          key: "dispute-invoices/settings",
                          label: (
                            <Link to="/dispute-invoices/settings">
                              Settings
                            </Link>
                          ),
                          icon: <SettingOutlined />,
                        },
                      ]
                      : []),
                    ...(manageDisputeInvoiceLogs
                      ? [
                        {
                          key: "dispute-invoices/logs",
                          label: (
                            <Link to="/dispute-invoices/logs">Logs</Link>
                          ),
                          icon: <FileSearchOutlined />,
                        },
                      ]
                      : []),
                  ],
                },
              ]
              : []),
          ],
        },
      ]
      : []),
  ];

  const bottomMenuItems: MenuProps["items"] = [
    ...(admin
      ? [
        {
          key: "admin",
          label: "Admin",
          icon: <UnlockOutlined />,
          children: [
            {
              key: "admin/requests",
              label: <Link to="/admin/requests">Requests</Link>,
              icon: <TrademarkOutlined />,
            },
            {
              key: "admin/settings",
              label: <Link to="/admin/settings">Settings</Link>,
              icon: <SettingOutlined />,
            },
          ],
        },
      ]
      : []),
    ...(manageBilling ?
      [
        {
          key: "billing",
          label: <Link to="/billing">Billing</Link>,
          icon: <CreditCardOutlined />,
        },
      ]
      : []
    ),
    {
      key: "settings",
      label: <Link to="/settings">Settings</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: "account",
      label: <Link to="/account">{claims?.name}</Link>,
      icon: <UserOutlined />,
    },
  ];

  if (!favorites) return null;

  const currentKey = getCurrentKey();

  if (props.burger) {
    return (
      <Menu
        mode="inline"
        style={{ width: "300px" }}
        selectedKeys={[currentKey]}
        items={[...topMenuItems, ...bottomMenuItems]}
      />
    );
  }

  return (
    <div className="menu">
      <div className="logo">
        <img src={logo} width={"70%"} alt="Logo" />
      </div>
      <div className="menu" style={{ overflow: "auto" }}>
        <Menu
          theme="dark"
          mode="vertical"
          selectedKeys={[currentKey]}
          items={topMenuItems}
        />
        <div className="space"></div>
        <Menu
          theme="dark"
          mode="vertical"
          className="info-menu"
          selectedKeys={[currentKey]}
          items={bottomMenuItems}
        />
      </div>
    </div>
  );
}
