import { Organization } from "./organization";

export class Request {
  requestId: string = "";
  date: string = "";
  organization?: Organization = undefined;
  accountName: string = "";
  type: string = "";
  status: string = "";
  startDate?: string = undefined;
  endDate?: string = undefined;
  executionTime?: number = undefined;
  requestData?: string = undefined;
  responseData?: string = undefined;
  errorMessage?: string = undefined;
}
