import { authService } from ".";
import { BehaviorSubject, map } from "rxjs";
import { Dashboard, Filter, Widget } from "@/models/dashboard";

export class DashboardService {
  regexpFindFields = /\{[^}]*\}/g;

  favorites = new BehaviorSubject<Dashboard[]>([]);
  dashboards = new BehaviorSubject<Dashboard[]>([]);
  isFetching = new BehaviorSubject(false);

  isFetchingOne = new BehaviorSubject(false);
  isFetchingValues = new BehaviorSubject(false);
  dashboard = new BehaviorSubject<Dashboard | null>(null);
  filters = new BehaviorSubject<Filter[] | null>(null);
  widgets = new BehaviorSubject<Widget[] | null>(null);

  initialFiltersSubscription = this.dashboard.pipe(
    map((dashboard) => {
      return dashboard?.filters ?? null;
    })
  ).subscribe(this.filters);

  isEditing = new BehaviorSubject<boolean>(false);

  constructor() {
    this.filters.subscribe(
      (filters) => { if (filters) this.getValues(filters) }
    );
  }

  dispose = async () => {
    this.dashboards.next([]);
  };

  disposeDashboard = async () => {
    this.dashboard.next(null);
  };

  getFavorites = async () => {
    try {
      const response = await authService.authFetch("/dashboards/favorite", {
        method: "GET",
      });
      if (response?.ok) {
        this.favorites.next(
          ((await response.json()) as Dashboard[]).map((x) =>
            Object.assign(new Dashboard(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  getDashboards = async () => {
    try {
      this.isFetching.next(true);

      const response = await authService.authFetch("/dashboards", {
        method: "GET",
      });
      if (response?.ok) {
        this.dashboards.next(
          ((await response.json()) as Dashboard[]).map((x) =>
            Object.assign(new Dashboard(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getDashboard = async (id: string) => {
    try {
      this.isFetchingOne.next(true);

      const response = await authService.authFetch("/dashboards/" + id, {
        method: "GET",
      });
      if (response?.ok) {
        this.dashboard.next(
          Object.assign(new Dashboard(), await response.json())
        );

        this.widgets.next(this.dashboard.value?.widgets ?? null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  editing = async () => {
    this.isEditing.next(true);
  };

  cancelEditing = async () => {
    this.isEditing.next(false);
  };

  setFavorite = async (id: string, value: boolean, dashboardPage: boolean) => {
    try {
      const response = await authService.authFetch(
        "/dashboards/" + id + "/favorite/" + value,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (dashboardPage) {
          this.getDashboard(id);
        } else {
          this.getDashboards();
        }
        this.getFavorites();
      }
    } catch (e) {
      console.log(e);
    }
  };

  setFilter = async (name: string, value: string) => {
    const filters = this.filters.value?.slice() ?? null;
    const filter = filters?.find((filter) => filter.name === name);
    if (filter) {
      filter.value.initialValue = value;
      this.filters.next(filters);
    }
  };

  getValues = async (filters: Filter[]) => {
    try {
      this.isFetchingValues.next(true);

      const dashboard = this.dashboard.value!;

      const response = await authService.authFetch("/dashboards/" + dashboard.id + "/widgets", {
        method: "POST",
        body: JSON.stringify({
          filters: filters.filter((filter) => filter.value.initialValue!!).map((filter) => ({ field: filter.field, value: filter.value.initialValue }))
        }),
      });
      if (response?.ok) {
        this.widgets.next(
          ((await response.json()) as Widget[]).map((x) =>
            Object.assign(new Widget(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingValues.next(false);
    }
  };
}
