import { SyncOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect, useRef } from "react";
import { remittanceService, vendorCentralCredentialService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import dayjs from "dayjs";
import {
  apiDateTimeFormat,
  dateFormat,
  renderCurrency,
  renderDate,
  renderStatus,
  tokenSeparator,
} from "@/utils";
import "./index.less";
import { TableRef } from "antd/es/table";
import { Remittance } from "@/models/remittance";
import { ColumnsType } from "antd/lib/table";
import { remittanceStatusList } from "@/models/enum/remittance-status";
import { Link } from "react-router-dom";

const { Title } = Typography;

export default function RemittancesPage() {
  const items: CollapseProps["items"] = [
    {
      key: "filters",
      label: "Filters",
      children: <FilterForm />,
    },
  ];

  const columns: ColumnsType<Remittance> = [
    {
      title: "Payment Number",
      key: "number",
      dataIndex: "number",
      width: "150px",
      render: (value: string, record: Remittance) => {
        return <Link to={record.number}>{value}</Link>;
      },
    },
    {
      title: "Remittance Date",
      key: "date",
      dataIndex: "date",
      width: "100px",
      render: renderDate,
    },
    {
      title: "Account Name",
      key: "accountName",
      dataIndex: "accountName",
      width: "250px",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "100px",
      render: renderStatus(remittanceStatusList),
    },
    {
      title: "Currency",
      key: "currency",
      dataIndex: "currency",
      width: "100px",
    },
    {
      title: "Payment Type",
      key: "paymentType",
      dataIndex: "paymentType",
      width: "150px",
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      align: "right",
      width: "100px",
      render: renderCurrency,
    },
  ];

  const isFetching = useObservable(remittanceService.isFetching);
  const remittances = useObservable(remittanceService.remittances);
  var tableRef = useRef<TableRef>(null);

  const onScroll = (event: any) => {
    if (event.target) {
      let maxScroll = Math.round(
        event.target.scrollHeight - event.target.clientHeight
      );
      let currentScroll = Math.round(event.target.scrollTop);
      if (currentScroll >= maxScroll - 100) {
        remittanceService.next();
      }
    }
  };

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const tbody =
        tableRef.current.nativeElement.querySelector(".ant-table-body");
      if (tbody) {
        tbody.addEventListener("scroll", onScroll);

        return () => {
          tbody.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, []);

  useEffect(() => {
    const subscribe = remittanceService.filters.subscribe(() => {
      tableRef.current?.nativeElement
        .querySelector(".ant-table-body")
        ?.scrollTo({ top: 0 });
      remittanceService.getRemittances();
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const onRefresh = () => {
    remittanceService.refresh();
  };

  const onExportCsv = () => {
    remittanceService.exportRemittances();
  }

  return (
    <div className="remittances-page">
      <Title level={3}>Remittances</Title>
      <Row className="actions" justify={"space-between"}>
        <Space>
          <Button icon={<SyncOutlined spin={isFetching} />} onClick={onRefresh}>
            Refresh
          </Button>
          <Button icon={<CloudDownloadOutlined />} onClick={onExportCsv}>
            Export
          </Button>
        </Space>
      </Row>
      <Collapse
        className="filters"
        size="small"
        bordered={false}
        items={items}
      />
      <Table
        ref={tableRef}
        columns={columns}
        dataSource={remittances}
        rowKey="id"
        size="small"
        pagination={false}
        scroll={{ y: "calc(100vh - 233px)" }}
      />
    </div>
  );
}

function FilterForm() {
  const filters = useObservable(remittanceService.filters);
  const allAccounts = useObservable(vendorCentralCredentialService.allAccounts);

  useEffect(() => {
    vendorCentralCredentialService.getVendorCentralCredentials();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.numbers}
          onChange={remittanceService.setNumbers}
          placeholder="Payment Numbers"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.dateFrom ? dayjs(filters.dateFrom, apiDateTimeFormat) : null
          }
          onChange={remittanceService.setDateFrom}
          placeholder="Remittance Date from"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <DatePicker
          format={dateFormat()}
          value={
            filters.dateTo ? dayjs(filters.dateTo, apiDateTimeFormat) : null
          }
          onChange={remittanceService.setDateTo}
          placeholder="Remittance Date to"
          style={{ width: "100%" }}
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={allAccounts}
          optionFilterProp="label"
          value={filters.accountNames}
          onChange={remittanceService.setAccountNames}
          placeholder="Account Names"
          allowClear
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          options={remittanceStatusList}
          value={filters.statuses}
          onChange={remittanceService.setStatuses}
          placeholder="Statuses"
          allowClear
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Col>
      <Col xl={4} md={6} sm={12} xs={24}>
        <Select
          value={filters.invoiceNumbers}
          onChange={remittanceService.setInvoiceNumbers}
          placeholder="Invoice Numbers"
          allowClear
          style={{ width: "100%" }}
          mode="tags"
          tokenSeparators={tokenSeparator}
          open={false}
          suffixIcon={false}
        />
      </Col>
    </Row>
  );
}
