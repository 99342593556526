import { BehaviorSubject } from "rxjs";
import { Organization } from "@/models/organization";
import { authService } from ".";

export class AdminOrganizationService {
  organizations = new BehaviorSubject<Organization[]>([]);
  isFetching = new BehaviorSubject<boolean>(false);
  organization = new BehaviorSubject<Organization | null>(null);
  isFetchingOne = new BehaviorSubject<boolean>(false);
  isAdding = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);
  isDeleting = new BehaviorSubject<boolean>(false);

  dispose = async () => {
    this.organizations.next([]);
  };

  disposeOrganization = async () => {
    this.organization.next(null);
  };

  getOrganizations = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch("/admin/organizations", {
        method: "GET",
      });
      if (response?.ok) {
        this.organizations.next(
          ((await response.json()) as Organization[]).map((x) =>
            Object.assign(new Organization(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getOrganization = async (id: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch(
        "/admin/organizations/" + id,
        {
          method: "GET",
        }
      );
      if (!!response?.ok) {
        this.organization.next(
          Object.assign(new Organization(), await response.json())
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  addOrganization = async (name: string) => {
    try {
      this.isAdding.next(true);
      const response = await authService.authFetch("/admin/organizations", {
        method: "POST",
        body: JSON.stringify({ name }),
      });
      if (!!response?.ok) {
        this.refresh();
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isAdding.next(false);
    }
    return false;
  };

  updateOrganization = async (id: string, name: string) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch("/admin/organizations", {
        method: "PATCH",
        body: JSON.stringify({ id, name }),
      });

      if (!!response?.ok) {
        this.organization.next(
          Object.assign(this.organization.value!!, { name })
        );
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };

  deleteOrganization = async (id: string) => {
    try {
      this.isDeleting.next(true);
      const response = await authService.authFetch(
        "/admin/organizations/" + id,
        {
          method: "DELETE",
        }
      );
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isDeleting.next(false);
    }
    return false;
  };
}
