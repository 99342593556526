export enum DisputeInvoiceStatus {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
  SKIP = "SKIP",
}

export const disputeInvoiceStatusList = [
  { value: DisputeInvoiceStatus.SUCCESS, label: "Success" },
  { value: DisputeInvoiceStatus.ERROR, label: "Error" },
  { value: DisputeInvoiceStatus.WARNING, label: "Warning" },
  { value: DisputeInvoiceStatus.SKIP, label: "Skip" },
];
