export enum RequestType {
  DISPUTE_INVOICES = "DISPUTE_INVOICES",
  GET_REMITTANCES = "GET_REMITTANCES",
  GET_PURCHASE_ORDERS = "GET_PURCHASE_ORDERS",
  ADD_INVOICES = "ADD_INVOICES",
  GET_INVOICE_NUMBERS = "GET_INVOICE_NUMBERS",
  GET_INVOICES = "GET_INVOICES",
  GET_SHORTAGE_CLAIMS = "GET_SHORTAGE_CLAIMS",
  GET_DISPUTES = "GET_DISPUTES",
  GET_CASES = "GET_CASES",
  UPDATE_INVOICES = "UPDATE_INVOICES",
  UPDATE_SHORTAGE_CLAIMS = "UPDATE_SHORTAGE_CLAIMS",
  UPDATE_PRICE_DISCREPANCIES = "UPDATE_PRICE_DISCREPANCIES",
  UPDATE_DISPUTES = "UPDATE_DISPUTES",
  UPDATE_CASES = "UPDATE_CASES",
}

export const requestTypeList = [
  { value: RequestType.DISPUTE_INVOICES, label: "Dispute Invoices" },
  { value: RequestType.GET_REMITTANCES, label: "Get Remittances" },
  { value: RequestType.GET_PURCHASE_ORDERS, label: "Get Purchase orders" },
  { value: RequestType.ADD_INVOICES, label: "Add Invoices" },
  { value: RequestType.GET_SHORTAGE_CLAIMS, label: "Get Shortage Claims" },
  { value: RequestType.GET_INVOICES, label: "Get Invoices" },
  {
    value: RequestType.GET_INVOICE_NUMBERS,
    label: "Get Invoice Numbers",
  },
  { value: RequestType.GET_DISPUTES, label: "Get Disputes" },
  { value: RequestType.GET_CASES, label: "Get Cases" },
  { value: RequestType.UPDATE_INVOICES, label: "Update Invoices" },
  {
    value: RequestType.UPDATE_SHORTAGE_CLAIMS,
    label: "Update Shortage Claims",
  },
  {
    value: RequestType.UPDATE_PRICE_DISCREPANCIES,
    label: "Update Price Discrepancies",
  },
  { value: RequestType.UPDATE_DISPUTES, label: "Update Disputes" },
  { value: RequestType.UPDATE_CASES, label: "Update Cases" },
];
