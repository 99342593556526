export class AdminVendorCentralCredential {
  id: string = "";
  organizationId: string = "";
  email: string = "";
  accounts: Account[] = [];
}

export class Account {
  name: string = "";
  payeeCode: string = "";
  marketplace: string = "";
  disputeInvoicesEnabled: boolean = true;
  lastSettlement: Date = new Date();
}

export class SelectAccount {
  organizationId: string = "";
  label: string = "";
  value: string = "";

  constructor(name: string, payeeCode: string, organizationId: string) {
    this.label = `(${payeeCode}) ${name}`;
    this.value = name;
    this.organizationId = organizationId;
  }
}
