import { authService } from ".";
import { Integration } from "@/models/integration";
import { BehaviorSubject } from "rxjs";

export class IntegrationService {
  apiToken = new BehaviorSubject<string>("");
  apiTokenLoading = new BehaviorSubject<boolean>(false);
  webhook = new BehaviorSubject<string>("");
  webhookLoading = new BehaviorSubject<boolean>(false);

  getIntegration = async () => {
    try {
      this.webhookLoading.next(true);
      this.apiTokenLoading.next(true);
      const response = await authService.authFetch("/integration/", {
        method: "GET",
      });
      if (response?.ok) {
        const integration = Object.assign(
          new Integration(),
          await response.json()
        );
        this.webhook.next(integration.webhookUrl);
        this.apiToken.next(integration.apiToken);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.webhookLoading.next(false);
      this.apiTokenLoading.next(false);
    }
  };

  rotateApiKey = async () => {
    try {
      this.apiTokenLoading.next(true);
      const response = await authService.authFetch(
        "/integration/rotate-token",
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        const integration = Object.assign(
          new Integration(),
          await response.json()
        );
        this.apiToken.next(integration.apiToken);
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.apiTokenLoading.next(false);
    }
  };

  updateWebhookUrl = async (url: string) => {
    try {
      this.webhookLoading.next(true);
      const response = await authService.authFetch("/integration/webhook", {
        method: "POST",
        body: JSON.stringify({ url }),
      });
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.webhookLoading.next(false);
    }
  };

  setWebhookUrl = async (url: string) => {
    this.webhook.next(url);
  };
}
