import { AggregateFunction } from "./enum/aggregate-function";
import { DataType } from "./enum/data-type";

export class CardSettings {
  field!: string;
  aggregateFunction!: AggregateFunction;
  dataType!: DataType;
  mask?: string;
}

export class LineSettings {
  aggregateFunction!: AggregateFunction;
  x!: LineSeriesSettings;
  y!: LineSeriesSettings[];
}

export class LineSeriesSettings {
  name!: string;
  field!: string;
}

export class PieSettings {
  aggregateFunction!: AggregateFunction;
  key!: LineSeriesSettings;
  value!: LineSeriesSettings;
}

export class TableSettings {
  columns!: ColumnSettings[];
  pagination!: PaginationSettings;
}

export class ColumnSettings {
  title!: string;
  aggregateFunction?: AggregateFunction;
  key!: string;
  field!: string;
  width!: number;
  align!: Align;
  dataType!: DataType;
  mask?: string;
  sortable?: boolean;
  nested?: boolean;
}

export enum Align {
  Right = "right",
  Left = "left",
  Center = "center"
}

export class PaginationSettings {
  enabled: boolean = true;
  pageSize: number = 10;
}