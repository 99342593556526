export enum CaseStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  MERGED = "MERGED",
  NEEDS_YOU_ATTENTION = "NEEDS_YOU_ATTENTION",
  PENDING_AMAZON_ACTION = "PENDING_AMAZON_ACTION",
}

export const caseStatusList = [
  { value: CaseStatus.APPROVED, label: "Approved", color: "green" },
  { value: CaseStatus.DENIED, label: "Denied", color: "darkorange" },
  { value: CaseStatus.PARTIALLY_APPROVED, label: "Partially approved", color: "orange" },
  { value: CaseStatus.MERGED, label: "Merged", color: "green" },
  { value: CaseStatus.NEEDS_YOU_ATTENTION, label: "Needs your attention", color: "darkyellow" },
  { value: CaseStatus.PENDING_AMAZON_ACTION, label: "Pending Amazon action" },
];
