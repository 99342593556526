import { BehaviorSubject, Subscription } from "rxjs";

export class MenuService {
  collapsed = new BehaviorSubject(
    localStorage.getItem("manuCollapsed") === "true"
  );
  subscription: Subscription | null = null;

  constructor() {
    this.subscription = this.collapsed.subscribe((value) => {
      localStorage.setItem("manuCollapsed", value.toString());
    });
  }

  dispose = async () => {
    this.subscription?.unsubscribe();
  };

  setCollapsed = (value: boolean) => {
    this.collapsed.next(value);
  };
}
