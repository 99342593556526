import { BehaviorSubject } from "rxjs";
import { authService } from ".";
import { Request } from "@/models/request";
import { Dayjs } from "dayjs";
import { apiDateFormat } from "@/utils";
import { RequestFilters } from "@/filters/request";

export class RequestService {
  requests = new BehaviorSubject<Request[]>([]);
  filters = new BehaviorSubject<RequestFilters>(new RequestFilters());
  isFetching = new BehaviorSubject(false);
  pageLoaded = [0];
  page = 0;
  limit = 100;

  dispose = async () => {
    this.requests.next([]);
    this.filters.next(new RequestFilters());
    this.page = 0;
  };

  getRequests = async () => {
    const requests = await this._getRequests(0, this.limit);
    if (requests) {
      this.pageLoaded = [0];
      this.requests.next(requests);
    }
  };

  refresh = async () => {
    const requests = await this._getRequests(
      0,
      (this.page + 1) * this.limit,
      false
    );
    if (requests) {
      this.requests.next(requests);
    }
  };

  resendFromFilter = async () => {
    try {
      const response = await authService.authFetch(
        `/admin/requests/resend`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  resend = async (requestId: string) => {
    try {
      const response = await authService.authFetch(
        `/admin/requests/${requestId}/resend`,
        {
          method: "POST"
        }
      );
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  next = async () => {
    if (!this.pageLoaded.includes(this.page + 1)) {
      this.pageLoaded.push(this.page + 1);
      const requests = await this._getRequests(this.page + 1, this.limit);
      if (!requests) {
        this.pageLoaded.pop();
      } else if (requests.length > 0) {
        this.requests.next([...this.requests.value, ...requests]);
      }
    }
  };

  _getRequests = async (
    page: number,
    limit: number,
    savePage: boolean = true
  ) => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        `/admin/requests?page=${page}&limit=${limit}`,
        {
          method: "POST",
          body: JSON.stringify(this.filters.value),
        }
      );
      if (response?.ok) {
        const requests = ((await response.json()) as Request[]).map((request) =>
          Object.assign(new Request(), request)
        );
        if (savePage && requests.length > 0) {
          this.page = page;
        }
        return requests;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
    return null;
  };

  setRequestIds = async (value: string[]) => {
    const filters = this.filters.value;
    filters.requestIds = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setDateFrom = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateFrom = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setDateTo = async (date: Dayjs | null) => {
    const filters = this.filters.value;
    filters.dateTo = date?.format(apiDateFormat);
    this.filters.next(filters);
  };

  setOrganizationIds = async (value: string[]) => {
    const filters = this.filters.value;
    filters.organizationIds = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setAccountNames = async (value: string[]) => {
    const filters = this.filters.value;
    filters.accountNames = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setTypes = async (value: string[]) => {
    const filters = this.filters.value;
    filters.types = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };

  setStatuses = async (value: string[]) => {
    const filters = this.filters.value;
    filters.statuses = value.length === 0 ? undefined : value;
    this.filters.next(filters);
  };
}
