export enum CreditMemoStatus {
  NOT_PRESENT = "NOT_PRESENT",
  EXPECTED = "EXPECTED",
  CREATED = "CREATED",
  MATCHED = "MATCHED",
}

export const creditMemoStatusList = [
  { value: CreditMemoStatus.NOT_PRESENT, label: "Not Present" },
  { value: CreditMemoStatus.EXPECTED, label: "Expected", color: "orange" },
  { value: CreditMemoStatus.CREATED, label: "Created", color: "darkorange" },
  { value: CreditMemoStatus.MATCHED, label: "Matched", color: "green" },
];
