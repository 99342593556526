import { Button, Col, Row, Space, Typography } from "antd";
import "./index.less";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import { useEffect } from "react";
import { StarFilled } from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { Link, useParams } from "react-router-dom";

const { Title } = Typography;

export default function DashboardsPage() {
  const dashboardId = useParams().id!!;
  const dashboards = useObservable(dashboardService.dashboards);

  useEffect(() => {
    dashboardService.getDashboards();
  }, [dashboardId]);

  const setFavorite = (dashboardId: string, favorite: boolean) => {
    return (e: any) => {
      dashboardService.setFavorite(dashboardId, !favorite, false);
      e.preventDefault();
    };
  };

  if (!dashboards) {
    return <Loader />;
  }

  return (
    <div className="dashboards-page">
      <Title level={3}>Dashboard List</Title>
      <Row gutter={[16, 16]}>
        {dashboards.map((dashboard) => {
          return (
            <Col xl={8} md={12} sm={12} xs={24} key={dashboard.id}>
              <Link to={`${dashboard.id}`}>
                <div className="card">
                  <Space>
                    <Button
                      icon={
                        <StarFilled
                          style={{
                            color: dashboard.settings?.favorite
                              ? "gold"
                              : "grey",
                            fontSize: "25px",
                          }}
                        />
                      }
                      onClick={setFavorite(
                        dashboard.id,
                        dashboard.settings?.favorite ?? false
                      )}
                      shape="circle"
                      type="text"
                    />
                    <Title className="title" level={3}>
                      {dashboard.name}
                    </Title>
                  </Space>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
