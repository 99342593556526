export class DisputeInvoiceSettings {
  createDispute?: CreateDisputeSettings = undefined;
  createCase?: CreateCaseSettings = undefined;
}

export class CreateDisputeSettings {
  ccNames: string[] = [];
}

export class CreateCaseSettings {
  phoneNumber?: string = undefined;
  ccEmails: string[] = [];
}
