export enum InvoiceStatus {
  PROOF_OF_DELIVERY_REQUIRED = "Proof of delivery required",
  PROOF_OF_DELIVERY_REJECTED = "Proof of delivery rejected",
  PROCESSING_PROOF_OF_DELIVERY = "Processing proof of delivery",
  CREDIT_MEMO_REQUIRED = "Credit memo required",
  PROCESSING_CREDIT_MEMO = "Processing credit memo",
  NOT_YET_SUBMITTED = "Not yet submitted",
  SUBMITTED = "Submitted",
  QUEUED_FOR_PAYMENT = "Queued for payment",
  PAID = "Paid",
  IN_REVIEW = "In review",
  REJECTED = "Rejected",
  NOT_FOUND = "Not found",
  CLOSED = "Closed",
}

export const invoiceStatusList = [
  {
    value: InvoiceStatus.PROOF_OF_DELIVERY_REQUIRED,
    label: "Proof of delivery required",
  },
  {
    value: InvoiceStatus.PROOF_OF_DELIVERY_REJECTED,
    label: "Proof of delivery rejected",
  },
  {
    value: InvoiceStatus.PROCESSING_PROOF_OF_DELIVERY,
    label: "Processing proof of delivery",
  },
  { value: InvoiceStatus.CREDIT_MEMO_REQUIRED, label: "Credit memo required" },
  {
    value: InvoiceStatus.PROCESSING_CREDIT_MEMO,
    label: "Processing credit memo",
  },
  { value: InvoiceStatus.NOT_YET_SUBMITTED, label: "Not yet submitted" },
  { value: InvoiceStatus.SUBMITTED, label: "Submitted" },
  { value: InvoiceStatus.QUEUED_FOR_PAYMENT, label: "Queued for payment" },
  { value: InvoiceStatus.PAID, label: "Paid" },
  { value: InvoiceStatus.IN_REVIEW, label: "In review" },
  { value: InvoiceStatus.REJECTED, label: "Rejected" },
  { value: InvoiceStatus.NOT_FOUND, label: "Not found" },
  { value: InvoiceStatus.CLOSED, label: "Closed" },
];
