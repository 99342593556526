// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 0;
  padding: 0;
  align-items: stretch;
  background-color: white;
  padding: 10px;
}
.widget-container .settings {
  position: absolute;
  top: 5px;
  right: 5px;
}
.widget-container .header {
  text-align: center;
  flex: 0 1 auto;
  margin-bottom: 15px;
  height: 56px;
  overflow: auto;
}
.widget-container .header h4 {
  margin: 0;
}
.widget-container .body {
  text-align: center;
  height: calc(100% - (56px + 15px));
}
.widget-container .body .export-button {
  position: absolute;
  top: 50px;
  right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/dashboard/widget.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,oBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AAhBA;EAkBQ,kBAAA;EACA,QAAA;EACA,UAAA;AACR;AArBA;EAwBQ,kBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;AAAR;AA5BA;EA+BY,SAAA;AAAZ;AA/BA;EAoCQ,kBAAA;EACA,kCAAA;AAFR;AAnCA;EAwCY,kBAAA;EACA,SAAA;EACA,WAAA;AAFZ","sourcesContent":[".widget-container {\n    display       : flex;\n    flex-direction: column;\n    position      : absolute;\n    left          : 0;\n    top           : 0;\n    right         : 0;\n    bottom        : 0;\n    width         : auto;\n    height        : auto;\n    overflow      : hidden;\n    margin        : 0;\n    padding       : 0;\n    align-items   : stretch;\n    background-color: white;\n    padding: 10px;\n\n    .settings {\n        position: absolute;\n        top: 5px;\n        right: 5px;\n    }\n\n    .header {\n        text-align: center;\n        flex   : 0 1 auto;\n        margin-bottom: 15px;\n        height: 56px;\n        overflow: auto;\n\n        h4 {\n            margin: 0;\n        }\n    }\n\n    .body {\n        text-align: center;\n        height: calc(100% - (56px + 15px));\n\n        .export-button {\n            position: absolute;\n            top: 50px;\n            right: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
