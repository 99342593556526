import { Button, Divider, Form, Input, message, Row, Typography } from "antd";
import { useEffect } from "react";
import { Permission } from "@/models/enum/permission";
import { authService, organizationService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import Loader from "@app/components/loader";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function OrganizationPage() {
  const [form] = Form.useForm();
  const organization = useObservable(organizationService.organization);
  const isUpdating = useObservable(organizationService.isUpdating);
  const claims = useObservable(authService.claims);
  const manageOrganization =
    claims?.permissions.includes(Permission.ManageOrganizations) ?? false;
  const [t] = useTranslation();

  useEffect(() => {
    organizationService.getOrganization();
  }, []);

  const onSubmit = ({ name }: { name: string }) => {
    organizationService.updateOrganization(name).then((response) => {
      if (response) {
        message.success("Organization updated", 4);
      }
    });
  };

  if (!organization) return <Loader />;

  return (
    <Row justify="center">
      <div style={{ width: "350px" }}>
        <Row justify="center">
          <Title level={3}>{organization.name}</Title>
        </Row>
        <Divider />
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            initialValue={organization.name}
            label="Name"
            name="name"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input />
          </Form.Item>
          {manageOrganization && (
            <Form.Item>
              <Button
                type="primary"
                block
                loading={isUpdating}
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </Row>
  );
}
