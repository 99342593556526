import { useObservableState } from "observable-hooks";
import { BehaviorSubject, Observable } from "rxjs";

export function useObservable<TState>(input: BehaviorSubject<TState>): TState;

export function useObservable<TState>(
  input: Observable<TState>
): TState | undefined;

export function useObservable<TState>(
  input: Observable<TState>
): TState | undefined {
  return useObservableState(
    input,
    input instanceof BehaviorSubject ? input.value : undefined
  );
}
