import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VendorCentralCredential } from "@/models/vendor-central-credential";
import { vendorCentralCredentialService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import "./index.less";

const { Search } = Input;
const { Title } = Typography;

export default function VendorCentralCredentialsPage() {
  const columns = [
    {
      title: "Email",
      key: "name",
      sorter: {
        compare: (a: VendorCentralCredential, b: VendorCentralCredential) =>
          a.email > b.email ? -1 : 1,
        multiple: 3,
      },
      render: (_: string, row: VendorCentralCredential) => (
        <Link to={row.id}>{row.email}</Link>
      ),
    },
  ];

  const isFetching = useObservable(vendorCentralCredentialService.isFetching);
  const vendorCentralCredentials = useObservable(
    vendorCentralCredentialService.vendorCentralCredentials
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    vendorCentralCredentialService.getVendorCentralCredentials();
  }, []);

  const onRefresh = () => {
    vendorCentralCredentialService.refresh();
  };

  const onSearch = (value: string) => {
    setSearchText(value.toLowerCase());
  };

  return (
    <div className="vendor-central-credentials-page">
      <Title level={3}>Vendor Central Credentials</Title>
      <Row className="actions" justify="start" gutter={[8, 8]}>
        <Col className="action" sm={8} xs={24}>
          <Button icon={<SyncOutlined spin={isFetching} />} onClick={onRefresh}>
            Refresh
          </Button>
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Search placeholder="Search account" onSearch={onSearch} />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={vendorCentralCredentials.filter((vendorCentralCredential) =>
          vendorCentralCredential.email.toLowerCase().includes(searchText)
        )}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
      />
    </div>
  );
}

// function AddVendorCentralCredentialButton() {
//   const [newVendorCentralCredentialVisible, setNewVendorCentralCredentialVisible] =
//     useState(false);

//   const showNewVendorCentralCredentialModal = () => {
//     setNewVendorCentralCredentialVisible(true);
//   };

//   const hideNewVendorCentralCredentialModal = () => {
//     setNewVendorCentralCredentialVisible(false);
//     vendorCentralCredentialsService.getVendorCentralCredentials();
//   };

//   return (
//     <>
//       <Button
//         type="primary"
//         icon={<PlusOutlined />}
//         onClick={showNewVendorCentralCredentialModal}
//       >
//         New Vendor Central Account
//       </Button>
//       <VendorCentralCredentialNewForm
//         visible={newVendorCentralCredentialVisible}
//         onFinish={hideNewVendorCentralCredentialModal}
//       />
//     </>
//   );
// }

// function VendorCentralCredentialNewForm(props: {
//   visible: boolean;
//   onFinish: () => void;
// }) {
//   const [form] = Form.useForm();
//   const { t } = useTranslation();
//   const [loading, setLoading] = useState(false);

//   const onSubmit = ({
//     email,
//     password,
//     otpSecret,
//     accountNames,
//   }: {
//     email: string;
//     password: string;
//     otpSecret: string;
//     accountNames: { name: string; enabled: boolean }[];
//   }) => {
//     setLoading(true);
//     vendorCentralCredentialsService
//       .addVendorCentralCredential(email, password, otpSecret, accountNames)
//       .then((response) => {
//         setLoading(false);
//         if (response) {
//           message.success("Vendor Central Account added", 4);
//           vendorCentralCredentialsService.getVendorCentralCredentials();
//           props.onFinish();
//           form.resetFields();
//         }
//       });
//   };

//   const onCancel = () => {
//     props.onFinish();
//     form.resetFields();
//   };

//   return (
//     <Modal
//       open={props.visible}
//       onCancel={props.onFinish}
//       title="New Vendor Central Account"
//       footer={[
//         <Button key="cancel" onClick={onCancel}>
//           {t("modal.new.cancel")}
//         </Button>,
//         <Button
//           key="submit"
//           type="primary"
//           loading={loading}
//           onClick={form.submit}
//         >
//           {t("modal.new.submit")}
//         </Button>,
//       ]}
//     >
//       <Form layout="vertical" form={form} onFinish={onSubmit}>
//         <Form.Item
//           label="Email"
//           name="email"
//           rules={[{ required: true, message: t("error.fieldRequired") }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           label="Password"
//           name="password"
//           rules={[{ required: true, message: t("error.fieldRequired") }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           label="Otp Secret"
//           name="otpSecret"
//           rules={[{ required: true, message: t("error.fieldRequired") }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item initialValue={[]} label="Account names" name="accountNames">
//           <Select mode="tags" showSearch={false} showArrow={false} allowClear />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// }
