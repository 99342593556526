import { HomeOutlined, LockOutlined, TeamOutlined } from "@ant-design/icons";
import { Menu, MenuProps, Typography } from "antd";
import { useLocation, Outlet, Link } from "react-router-dom";
import "./index.less";

const { Title } = Typography;

export default function SettingsPage() {
  const location = useLocation();

  const currentKey = location.pathname.split("/")[2];

  const menuItems: MenuProps["items"] = [
    {
      key: "users",
      label: <Link to="users">Users</Link>,
      icon: <TeamOutlined />,
    },
    {
      key: "roles",
      label: <Link to="roles">Roles</Link>,
      icon: <LockOutlined />,
    },
    {
      key: "organization",
      label: <Link to="organization">Organization</Link>,
      icon: <HomeOutlined />,
    },
  ];

  return (
    <div className="settings-page">
      <Title level={3}>Settings</Title>

      <Menu mode="horizontal" selectedKeys={[currentKey]} items={menuItems} />
      <div className="settings-container">
        <Outlet />
      </div>
    </div>
  );
}
