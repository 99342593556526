import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { useEffect } from "react";
import { useObservable } from "@/utils/use-observable";
import "./index.less";
import Loader from "@/app/components/loader";
import { DisputeInvoiceSettings } from "@/models/dispute-invoice-settings";
import { disputeInvoiceSettingsService } from "@/services";
import { tokenSeparator } from "@/utils";

const { Title } = Typography;

export default function DisputeInvoiceSettingsPage() {
  const isUpdating = useObservable(disputeInvoiceSettingsService.isUpdating);
  const settings = useObservable(disputeInvoiceSettingsService.settings);
  const [form] = Form.useForm();

  useEffect(() => {
    disputeInvoiceSettingsService.getSettings();
  }, []);

  const onSubmit = ({
    ccNames,
    phoneNumber,
    ccEmails,
  }: {
    ccNames: string[];
    phoneNumber: string;
    ccEmails: string[];
  }) => {
    disputeInvoiceSettingsService
      .updateSettings(
        Object.assign(new DisputeInvoiceSettings(), {
          createDispute: {
            ccNames,
          },
          createCase: {
            phoneNumber: phoneNumber === "" ? null : phoneNumber,
            ccEmails,
          },
        })
      )
      .then((response) => {
        if (response) {
          message.success("Dispute invoice settings updated", 4);
        }
      });
  };

  if (!settings) return <Loader />;

  return (
    <Row className="dispute-invoice-settings-page">
      <Col flex={1}>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row justify="center">
            <div style={{ width: "700px" }}>
              <Row justify="center">
                <Title level={3}>Dispute Invoice Settings</Title>
              </Row>
              <Divider />
              <Title level={3}>Create dispute</Title>
              <Form.Item
                initialValue={settings.createDispute?.ccNames}
                label="CC Name"
                name="ccNames"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  mode="tags"
                  tokenSeparators={tokenSeparator}
                  open={false}
                  suffixIcon={false}
                />
              </Form.Item>
              <Divider />
              <Title level={3}>Create case</Title>
              <Form.Item
                initialValue={settings.createCase?.phoneNumber}
                label="Phone Number"
                name="phoneNumber"
              >
                <Input />
              </Form.Item>
              <Form.Item
                initialValue={settings.createCase?.ccEmails}
                label="CC Email"
                name="ccEmails"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  mode="tags"
                  tokenSeparators={tokenSeparator}
                  open={false}
                  suffixIcon={false}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  loading={isUpdating}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
