export class VendorCentralCredential {
  id: string = "";
  email: string = "";
  accounts: Account[] = [];
}

export class Account {
  name: string = "";
  payeeCode: string = "";
  marketplace: string = "";
  disputeInvoicesEnabled: boolean = true;
  lastSettlement?: string = undefined;
  lastUpdate?: string = undefined;
  updating: boolean = false;
}

export class SelectAccount {
  label: string = "";
  value: string = "";

  constructor(name: string, payeeCode: string) {
    this.label = `(${payeeCode}) ${name}`;
    this.value = name;
  }
}
