import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Permission } from "@/models/enum/permission";
import { authService, roleService, userService } from "@/services";
import { gravatar } from "@/utils";
import { useObservable } from "@/utils/use-observable";
import Loader from "./loader";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

export function UserForm({ id, account }: { id: string; account?: boolean }) {
  const [form] = Form.useForm();
  const user = useObservable(userService.user);
  const isFetching = useObservable(userService.isFetchingOne);
  const isUpdating = useObservable(userService.isUpdating);
  const isDeleting = useObservable(userService.isDeleting);
  const isTogglingStatus = useObservable(userService.isTogglingStatus);
  const roles = useObservable(roleService.roles);
  const claims = useObservable(authService.claims);
  const router = useNavigate();
  const manageUser =
    claims?.permissions.includes(Permission.ManageRoles) ?? false;
  const [t] = useTranslation();

  useEffect(() => {
    claims?.sub === id ? userService.getMe() : userService.getUser(id);

    if (!account) {
      roleService.getRoles();
    }

    return () => {
      userService.disposeUser();
    };
  }, [id, account, claims?.sub]);

  const onSubmit = ({
    name,
    email,
    role,
  }: {
    name: string;
    email: string;
    role: string;
  }) => {
    (account
      ? userService.updateMe(user!!.id, name, email, user!!.role.id)
      : userService.updateUser(user!!.id, name, email, role)
    ).then((response) => {
      if (response) {
        message.success(t("user.updated"), 4);
      }
    });
  };

  const onChangeStatus = () => {
    if (user) {
      (user.enabled
        ? userService.disableUser(user.id, false)
        : userService.enableUser(user.id, false)
      ).then((response) => {
        if (response) {
          message.success(t(`user.${user.enabled ? "disabled" : "enabled"}`), 4);
        }
      });
    }
  };

  const onDelete = () => {
    userService.deleteUser(id).then((response) => {
      if (response) {
        message.success(t("user.deleted"), 4);
        router("/settings/users");
      }
    });
  };

  const onLogout = () => {
    authService.logout().then(() => {
      router("/login");
    });
  };

  if (!user || isFetching) return <Loader />;

  return (
    <Row justify="center">
      <div style={{ width: "350px" }}>
        <Row align="middle">
          <Avatar size={70} src={gravatar(user?.email, 200)}></Avatar>
          <Title level={3} style={{ margin: "8px 0px 0px 10px" }}>
            {user.name}
          </Title>
        </Row>
        <Divider />
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            initialValue={user.name}
            label={t("user.name")}
            name="name"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input disabled={!user.enabled} />
          </Form.Item>
          <Form.Item
            initialValue={user.email}
            label={t("user.email")}
            name="email"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input disabled={!user.enabled} />
          </Form.Item>
          {!account && (
            <Form.Item
              initialValue={user.role.id}
              label={t("user.role")}
              name="role"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={!user.enabled}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {roles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {user.enabled && (
            <>
              {(account || manageUser) && (
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={isUpdating}
                    htmlType="submit"
                  >
                    {t("user.save")}
                  </Button>
                </Form.Item>
              )}
              {claims?.sub !== id && manageUser && (
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    block
                    loading={isDeleting}
                    htmlType="button"
                    onClick={onDelete}
                  >
                    {t("user.delete")}
                  </Button>
                </Form.Item>
              )}
              {claims?.sub === id && (
                <>
                  <Divider />
                  <ChangePasswordButton />
                  <Divider />
                  <Button danger block onClick={onLogout}>
                    {t("user.logout")}
                  </Button>
                </>
              )}
            </>
          )}
          {claims?.sub !== id && manageUser && (
            <>
              <Divider />
              <Button
                block
                type="primary"
                ghost={user.enabled}
                loading={isTogglingStatus}
                onClick={onChangeStatus}
              >
                {user.enabled ? "Disable User" : "Enable User"}
              </Button>
            </>
          )}
        </Form>
      </div>
    </Row>
  );
}

export function ChangePasswordButton() {
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [t] = useTranslation();

  const onChangePassword = () => {
    hideChangePasswordModal();
  };

  const showChangePasswordModal = () => {
    setChangePasswordVisible(true);
  };

  const hideChangePasswordModal = () => {
    setChangePasswordVisible(false);
  };

  return (
    <>
      <Button block onClick={showChangePasswordModal}>
        {t("changePassword.title")}
      </Button>
      <Modal
        open={changePasswordVisible}
        onCancel={hideChangePasswordModal}
        title={t("changePassword.title")}
        footer={null}
        width={400}
      >
        <ChangePassword onSubmit={onChangePassword} />
      </Modal>
    </>
  );
}

function ChangePassword(props: { onSubmit: () => void }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const onSubmit = ({
    password,
    newPassword,
  }: {
    password: string;
    newPassword: string;
  }) => {
    setLoading(true);
    authService.changePassword(password, newPassword).then((changed) => {
      setLoading(false);
      if (!changed) {
        message.success(t("changePassword.success"), 4);
        props.onSubmit();
        form.resetFields();
      }
    });
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={t("changePassword.confirmNewPassword")}
        name="confirmPassword"
        dependencies={["newPassword"]}
        rules={[
          { required: true, message: t("error.fieldRequired") },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                t("changePassword.error.passwordNotMatch")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" block loading={loading} htmlType="submit">
          {t("changePassword.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
}
