import { Button, Form, Input, message, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { authService } from "@/services";
import logo from "@/logo.svg";
import "./index.less";

export default function ResetPasswordPage() {
  const { t } = useTranslation();

  const onFinish = (values: { email: string }) => {
    authService.resetPassword(values.email).then((response) => {
      if (response) {
        message.success(t("resetPassword.success"), 10);
      }
    });
  };

  return (
    <div className="reset-password-page">
      <div>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <h2 className="title">{t("resetPassword.title")}</h2>
        <div className="fixed-width-page">
          <div className="content">
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label={t("resetPassword.email")} name="email">
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {t("resetPassword.submit")}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Text className="row-link">
              {t("resetPassword.alreadyHavePassword")}{" "}
              <Link to="/login">{t("resetPassword.signIn")}</Link>
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
}
