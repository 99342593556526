import { Amount } from "./amount";
import { RemittanceType } from "./enum/remittance-type";

export class Remittance {
  id: string = "";
  number: string = "";
  payeeCode: string = "";
  accountName: string = "";
  date: string = "";
  amount!: Amount;
  status: string = "";
  currency: string = "";
  paymentType: string = "";
  details?: RemittanceDetail[] = undefined;
}

export class RemittanceDetail {
  invoiceNumber?: string = undefined;
  date?: string = undefined;
  type?: RemittanceType = undefined;
  description?: string = undefined;
  currency: string = "";
  amount?: Amount = undefined;
  netAmountPaid?: Amount = undefined;
  remainingAmount?: Amount = undefined;
}
