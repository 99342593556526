// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remittances-page {
  padding: 20px;
}
.remittances-page .actions,
.remittances-page .filters {
  margin-bottom: 20px;
}
.remittances-page .table-row-error {
  background-color: #ff9b9b;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/data/remittances/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;;EAII,mBAAA;AAEJ;AANA;EAOI,yBAAA;AAEJ","sourcesContent":[".remittances-page {\n  padding: 20px;\n  .actions,\n  .filters {\n    margin-bottom: 20px;\n  }\n  .table-row-error {\n    background-color: #ff9b9b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
