// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remittance-page {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.remittance-page .remittance-details * {
  font-size: 17px !important;
}
.remittance-page .remittance-details .label-column {
  margin-right: 20px;
  width: 200px;
}
.remittance-page .remittance-details .value-column {
  width: 200px;
}
.remittance-page .expanded-row-block {
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/data/remittances/remittance/index.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;AACF;AAJA;EAMI,0BAAA;AACJ;AAPA;EASM,kBAAA;EACA,YAAA;AACN;AAXA;EAaM,YAAA;AACN;AAdA;EAiBI,YAAA;AAAJ","sourcesContent":[".remittance-page {\n  width: 100%;\n  height: 100%;\n  padding: 20px;\n  .remittance-details {\n    *{\n    font-size: 17px !important;\n    }\n    .label-column{\n      margin-right: 20px;\n      width: 200px;\n    }\n    .value-column {\n      width: 200px;\n    }\n  }\n  .expanded-row-block {\n    margin: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
