import { DisputeInvoiceAction } from "./enum/dispute-invoice-action";
import { DisputeInvoiceStatus } from "./enum/dispute-invoice-status";

export class DisputeInvoiceLog {
  requestId: string = "";
  accountName: string = "";
  invoiceNumber: string = "";
  action?: DisputeInvoiceAction;
  status?: DisputeInvoiceStatus;
  date: string = "";
  disputeId?: string = undefined;
  disputeDate?: string = undefined;
  caseId?: string = undefined;
  caseDate?: string = undefined;
  message?: string = undefined;
}
